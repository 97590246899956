/**
 * 毕业证详情
 */
import React from 'react';
import moment from 'moment';

import {
  Modal, message,
  DatePicker,
  Input,
  Form,
  Button
} from 'antd';

import FollowApi from '../api/FollowApi'

const FormOption = {
  layout: "vertical"
};
const FormItem = Form.Item;
const { TextArea } = Input;
const DatePickerFormat = "YYYY-MM-DD";
const FormItemOption = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 }
};
const DateSubmitFormat = "YYYY-MM-DD hh:mm:ss";

export class FollowDetail extends React.Component {

  constructor(props){
    super(props);
    FormOption.onSubmit = this.save.bind(this);
    this.state = {
      loading:false,
      followDetail:{}
    }
  }

  save(e){
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        values = {
          ...values,
          nextTime: values.nextTime?moment(values.nextTime).format(DateSubmitFormat):'',
          resumeId:this.props.resumeId
        }
        FollowApi.cms_follow_save(values)
        .then(({data})=>{
          if(data.code == '200'){
            message.success('保存成功');
            if(this.props.onSave){
              this.props.onSave(data.data)
            }
          }else{
            message.error(data.message || '保存失败');
          }
          this.setState({
            loading:false
          })
        })
        .catch(err=>{
          message.error(err.message || '保存失败');
          this.setState({
            loading:false
          })
        })
      }
    });
  }

  render(){
    let {followDetail,loading} = this.state;
    const { getFieldDecorator } = this.props.form;
    return(
      <div>
        <Form {...FormOption}>
          <div className="contract-form-row">
            <FormItem {...FormItemOption} label="跟踪记录">
              {getFieldDecorator("remark",{
                rules: [{
                    required: true,
                    message: "请输入跟踪记录"
                  }]
              })(
                <TextArea rows={4} placeholder="请输入跟踪记录"/>
              )}
            </FormItem>
          </div>
          <div className="contract-form-row">
            <FormItem {...FormItemOption} label="下次追踪时间">
              {getFieldDecorator("nextTime",{
                rules: []
              })(
                <DatePicker
                  placeholder="请输入下次追踪时间"
                  format={DatePickerFormat}
                />
              )}
            </FormItem>
          </div>
          <div className="contract-form-row contract-form-btn">
            <FormItem>
              <Button
                type="primary"
                htmlType="submit"
                block={true}
                loading={loading}
              >
                保存
              </Button>
            </FormItem>
          </div>
        </Form>
      </div>
    )
  }
}

const FollowFormDetail = Form.create({ name: "edit-or-add-form" })(
  FollowDetail
);

class FollowDetailModal extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      visible:false
    }
  }
  show(){
    this.setState({
      visible:true
    })
  }

  onOk = ()=>{
    this.setState({
      visible:true
    })
  }

  onCancel(){
    this.setState({
      visible:false
    })
  }

  onSave(){
    this.onCancel();
    this.props.onModalClose();
  }

  render(){
    let {visible} = this.state;
    return(
      <div>
        <Modal
          destroyOnClose={true}
          title="增加记录"
          visible={visible}
          onOk={this.onOk}
          width={'800px'}
          footer={null}
          onCancel={()=>this.onCancel()}>
        {
          <FollowFormDetail resumeId={this.props.resumeId} onSave={()=>this.onSave()}/>
        }
        </Modal>
      </div>
    )
  }
}

export default FollowDetailModal;
