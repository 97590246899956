import React from 'react';
import Cookies from 'js-cookie';
import lodash from 'lodash';

import {Layout,Menu,Tabs,message,Button,Icon} from 'antd';

import style from './BaseLayout.less';

const {Sider,Content,Header}  = Layout;

const {TabPane} = Tabs;

import Api from '../api';

// 配置路由

import StaffPage from '../pages/StaffPage';
import ResumesListPage from '../pages/ResumesListPage';
import MyOwnerResumesListPage from '../pages/MyOwnerResumesListPage';
import MyDealResumesListPage from '../pages/MyDealResumesListPage';

const MenuItemList = [
  {
    text:'证书检索',
    component:ResumesListPage,
    route:'/list'
  },{
    text:'我的独占人才',
    component:MyOwnerResumesListPage,
    route:'/myownerlist'
  },{
    text:'我的成交人才',
    component:MyDealResumesListPage,
    route:'/mydeallist'
  }
]

const AdminMenuItemList = [
  {
    text:'证书检索',
    component:ResumesListPage,
    route:'/list'
  },{
    text:'我的独占人才',
    component:MyOwnerResumesListPage,
    route:'/myownerlist'
  },{
    text:'我的成交人才',
    component:MyDealResumesListPage,
    route:'/mydeallist'
  },{
    text:'员工列表',
    component:StaffPage,
    route:'/staff/list'
  }
]


class BaseLayout extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      tabs:[],
      activeTab:{},
      selectedKeys:[],
      collapsed:false,
      memberInfo:{}
    }
  }
  // 菜单点击
  showPage(item){
    let {selectedKeys,tabs} = this.state;
    let index = selectedKeys.indexOf(item.route);
    // 当前没有激活
    if(index === -1){
      // 设置url
      this.props.history.push({
        pathname:item.route
      })
      // 判断 tab列表中是否存在
      let exsitTab = tabs.find(tab=>{
        return tab.route === item.route;
      })
      if(!exsitTab){
        exsitTab = item;
        tabs.push(item);
      }
      // 设置tab
      this.setState({
        selectedKeys:[item.route],
        tabs,
        activeTab:exsitTab
      })
    }
  }

  // tab点击
  tabClick(key,type){
    let {DefaultMenuItemList} = this.state;
    let activeTab = DefaultMenuItemList.find(tab=>{
      return tab.route === key
    })

    if(this.state.activeTab.route === key){
      // 点击的是当前已经激活的tab
    }else{
      // this.showPage(activeTab,)
      this.setState({
        activeTab,
        selectedKeys:[key]
      })
    }
  }

  // tab 关闭
  onEdit(targetKey, action){
    this[action](targetKey);
  }

  remove(targetKey){
    let {tabs,activeTab} = this.state;
    if(tabs.length ===1){
      message.error('最后一个不能关闭')
    }else if(activeTab.route === targetKey){
      // message.error('不能关闭当前正在打开的菜单')
      let newTabs = tabs.filter(tab=>{
        return tab.route !== targetKey;
      })
      activeTab = newTabs[newTabs.length-1];
      // 设置url
      this.props.history.push({
        pathname:activeTab.route
      })
      this.setState({
        tabs:newTabs,
        activeTab
      })
    }else{
      let newTabs = tabs.filter(tab=>{
        return tab.route !== targetKey;
      })
      this.setState({
        tabs:newTabs
      })
    }
  }

  toggleCollapsed = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }

  buildMenu(){
    let {history} = this.props;
    let {pathname} = history.location;
    // 获取用户信息
    let memberInfo = Cookies.getJSON('jianli_memberInfo') ||{};
    let DefaultMenuItemList = MenuItemList;
    if(memberInfo.level==0){
      DefaultMenuItemList = AdminMenuItemList;
    }
    // 找到激活的菜单
    let defaultMenu = DefaultMenuItemList.find(item=>{
      return item.route === pathname
    })
    if(!defaultMenu){
      defaultMenu = DefaultMenuItemList[0]
    }
    this.setState({
      tabs:[defaultMenu],
      activeTab:defaultMenu,
      selectedKeys:[defaultMenu.route],
      collapsed:false,
      memberInfo,
      DefaultMenuItemList
    })
  }

  // 加载用户信息
  componentDidMount(){
    Api.cms_member_view()
    .then(({data})=>{
      if(data.code == '200'){
        Cookies.set('jianli_memberInfo',data.data,{domain:'192.168.0.109'});
        Cookies.set('jianli_memberInfo',data.data,{domain:'hourmore.com'});
        Cookies.set('jianli_memberInfo',data.data,{domain:'127.0.0.1'});
        Cookies.set('jianli_memberInfo',data.data);
        this.buildMenu()
      }else{
        this.props.history.push({
          pathname:'/login'
        })
      }
    })
  }
  // 退出登录
  logout(){
    let cookieObj = Cookies.getJSON();

    // 清除所有的 cookie
    lodash.forEach(cookieObj,(value,key)=>{
      Cookies.remove(key,{ path: '/' ,domain:'127.0.0.1'});// dev
      Cookies.remove(key,{ path: '/' ,domain:'192.168.0.109'});// dev
      Cookies.remove(key,{ path: '/' ,domain:'.hourmore.com'}); // prod
      Cookies.remove(key,{ path: '/'}); // prod
    })
    this.props.history.push({
      pathname:'/login'
    })
  }
  render(){
    let {activeTab,selectedKeys,collapsed} = this.state;
    let memberInfo = Cookies.getJSON('jianli_memberInfo') ||{};
    let DefaultMenuItemList = MenuItemList;
    if(memberInfo.level==0){
      DefaultMenuItemList = AdminMenuItemList;
    }
    return(
      <Layout>
        <Sider collapsed={collapsed}>
          <div className={style.logo}>
            <Button type="primary" onClick={this.toggleCollapsed} style={{ marginBottom: 16 }}>
              <Icon type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'} />
            </Button>
          </div>
          <Menu
            mode={'inline'}
            theme={'dark'}
            selectedKeys={selectedKeys}>
            {
              DefaultMenuItemList.map((menu,index)=>{
                return(
                  <Menu.Item key={menu.route} onClick={()=>this.showPage(menu)}>
                    <Icon type="pie-chart" />
                    <span>{menu.text}</span>
                  </Menu.Item>
                )
              })
            }
          </Menu>
        </Sider>
        <Layout className={style.layout}>
            <Header className={style.light}>
                欢迎您：<span>{this.state.memberInfo.userName}</span> <Button icon={'logout'} onClick={()=>this.logout()} type="primary" size={'small'}>退出</Button>
            </Header>
            <Content className={style.content}>
              {/* tab导航区域 */}
              <Tabs
                activeKey={activeTab.route}
                type={'editable-card'}
                hideAdd={true}
                onEdit={(targetKey, action)=>this.onEdit(targetKey, action)}
                onTabClick={(key)=>this.tabClick(key)}>
                {
                  this.state.tabs.map((tab,index)=>{
                    let Com = tab.component;
                    return (
                      <TabPane tab={tab.text}  key={tab.route} closable={true}>
                        <Com />
                      </TabPane>
                    )
                  })
                }
              </Tabs>
            </Content>
        </Layout>
      </Layout>
    )
  }
}

export default BaseLayout;
