import React from 'react';
import { HashRouter as Router, Route, Switch,Redirect } from "react-router-dom";
import {LocaleProvider} from 'antd';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');
import BaseLayout from './layout/BaseLayout';
import LoginPage from './pages/LoginPage';

class App extends React.Component{
  render(){
    return(
      <LocaleProvider locale={zh_CN}>
        <Router>
          <Switch>
            <Route exact path="/login" component={LoginPage} />
            <Route path="/" component={BaseLayout}/>
          </Switch>
        </Router>
      </LocaleProvider>
    )
  }
}

export default App;