import Axios from "axios";
import moment from "moment";
import lodash from "lodash";

const DateFormat = "YYYY-MM-DD HH:mm:ss";

/**查询简历列表 */
function cms_resume_getResumes(params = {}) {

  lodash.forEach(params, (value, key)=>{
    if(value == -1){
      params[key] = undefined
    }
  })
  let {certificationTimeStart,diplomaTimeStart,nextTimeStart,validityTimeStart,validityTimeBStart} = params;

  let certificationS = certificationTimeStart?certificationTimeStart.startOf('year').format(DateFormat):undefined;
  let certificationE = certificationTimeStart?certificationTimeStart.endOf('year').format(DateFormat):undefined;

  let diplomaS = diplomaTimeStart?diplomaTimeStart.startOf('year').format(DateFormat):undefined;
  let diplomaE = diplomaTimeStart?diplomaTimeStart.endOf('year').format(DateFormat):undefined;
  let nextTime = nextTimeStartFormat(nextTimeStart);
  let validityTime = validityTimeStartFormat(validityTimeStart,'validityTimeStart','validityTimeEnd');
  let validityTimeB = validityTimeStartFormat(validityTimeBStart,'validityTimeBStart','validityTimeBEnd');

  params = {
    page:1,
    ...params,
    pageSize: 15,
    certificationTimeStart:certificationS,
    certificationTimeEnd:certificationE,
    diplomaTimeStart:diplomaS,
    diplomaTimeEnd:diplomaE,
    ...nextTime,
    ...validityTime,
    ...validityTimeB,
  }

  return Axios.post("/cms/resume/getResumes", params);
}
/**查询简历详情 */
function cms_resume_getById(id){
  return Axios.get('/cms/resume/getById',{
    params:{
      id
    }
  })
}

/**查询当前登录的用户信息 */
function cms_member_view() {
  return Axios.get("/cms/member/view");
}

/**处理下次追踪日期 */
function nextTimeStartFormat(value){
  // 10天内
  if(value == 1){
    let s =  moment();
    let e = moment().add(10,'d');
    return {
      nextTimeStart:s.startOf('day').format(DateFormat),
      nextTimeEnd:e.endOf('day').format(DateFormat)
    }
  // 1个月内
  }else if(value == 2){
    let s = moment();
    let e = moment().add(1,'M');
    return {
      nextTimeStart:s.startOf('day').format(DateFormat),
      nextTimeEnd:e.endOf('day').format(DateFormat)
    }
  // 2个月内
  }else if(value == 3){
    let s = moment();
    let e = moment().add(2,'M');
    return {
      nextTimeStart:s.startOf('day').format(DateFormat),
      nextTimeEnd:e.endOf('day').format(DateFormat)
    }
  }else if(value == -1){
    return {
      nextTimeStart:undefined,
      nextTimeEnd:undefined
    }
  }
}

function validityTimeStartFormat(value,startKey,endKey){
  // 3个月内
  if(value == 1){
    let s =  moment();
    let e = moment().add(3,'M');
    return {
      [startKey]:s.startOf('day').format(DateFormat),
      [endKey]:e.endOf('day').format(DateFormat)
    }
  // 6个月内
  }else if(value == 2){
    let s = moment();
    let e = moment().add(6,'M');
    return {
      [startKey]:s.startOf('day').format(DateFormat),
      [endKey]:e.endOf('day').format(DateFormat)
    }
  // 已过期
  }else if(value == 3){
    let e = moment();
    // let e = moment().add(2,'M');
    return {
      [startKey]:undefined,//s.startOf('day').format(DateFormat),
      [endKey]:e.endOf('day').format(DateFormat)
    }
  }else if(value == -1){
    return {
      [startKey]:undefined,
      [endKey]:undefined
    }
  }
}

/**
 * 上传错误简历查询
 */
function cms_resume_err(params = {}) {
  return Axios.post("/cms/resume/getTodayUploadFailResumes", params);
}

function cms_resume_delUploadFailResumes(){
  return Axios.post('cms/resume/delUploadFailResumes');
}

/**
 * 新增/更新简历
 */
function cms_resume_add(params = {}) {
  return Axios.post("/cms/resume/save", params);
}

/**
 * 修改简历等级
 */
function cms_resume_edit_level(params = {}) {
  return Axios.post("/cms/resume/updateResumeLev", params);
}




export default {
  cms_resume_getResumes,
  cms_member_view,
  cms_resume_err,
  cms_resume_delUploadFailResumes,
  cms_resume_getById,
  cms_member_view,
  cms_resume_add,
  cms_resume_edit_level,
}
