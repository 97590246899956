import React from 'react';
import moment from 'moment';
import Cookies from 'js-cookie';
import {
  Button,
  Table,
  message
} from 'antd';

import style from './ResumesListPage.less';

import SearchForm from '../component/SearchForm';

import Api from '../api';

const Colums = [
  {
    title:'序号',
    dataIndex:'index',
    width: '3%'
  },
  {
    title: '姓名',
    dataIndex: 'realName',
    width: '5%'
  }, {
    title: '证书种类',
    dataIndex: 'credentialsType',
    width: '5%'
  }, {
    title: '证书专业',
    dataIndex: 'credentialsMajor',
    width: '5%'
  }, {
    title: '证书等级',
    dataIndex: 'credentialsLevel',
    width: '5%'
  }, {
    title: '发证地',
    dataIndex: 'certificationAuthority',
    width: '3%'
  }, {
    title: '发证时间',
    dataIndex: 'certificationTime',
    width: '8%',
    sorter: true
  }, {
    title: '现注册地',
    dataIndex: 'registeredPlace',
    width: '5%'
  }, {
    title: '证书有效期',
    dataIndex: 'validityTime',
    width: '8%',
    sorter: true
  }, {
    title: 'B证',
    dataIndex: 'otherB',
    width: '3%'
  }, {
    title: 'B证有效期',
    dataIndex: 'validityTimeB',
    width: '8%',
    sorter: true
  }, {
    title: '是否为单证',
    dataIndex: 'single',
    width: '6%'
  },{
    title: '三证一表/网查结果',
    dataIndex: 'net',
  }, {
    title: '下次联系日期',
    dataIndex: 'nextTime',
    width: '8%',
    sorter: true
  },{
    title: '上次联系日期',
    dataIndex: 'lastTime',
    width: '8%',
    sorter: true
  },  {
    title: '上次联系情况',
    dataIndex: 'followRemark',
    width: '10%'
  }
]
// 新增简历弹出层
import ResumesModal from '../component/Resumes/Modal';
class MyOwnerResumesListPage extends React.Component {
  constructor(props){
    super(props);
    this.searchFormRef = React.createRef();

    // 新增简历弹出层 Ref
    this.resumesModalRef = React.createRef();

    let localColums = Colums.map(col=>{
      return {
        ...col,
        align: 'center'
      }
    })

    this.state = {
      current:1,
      totalCount:0,
      loading:false,
      dataSource:[],
      columns:localColums,
      disableList:[
        'diplomaTimeStart',
        'diplomaMajor',
        'diplomaLevel',
        'level',
        'validityTimeStart',
        'validityTimeBStart'
      ],
      field:null,
      order:false
    }
  }
  onSearch(page,field,order){
    // mySort:排序字段:1发证时间，2证书有效期，3B证有效期，4合同开始时间，5合同结束时间，6上次联系时间，7下次联系时间 
    // desc:升序0/降序1 
    field = field || this.state.field;
    order = order || this.state.order;
    let mySort,desc;
    // 未设置排序字段
    if(!field){
      order = 'descend'
    }
    desc = order === 'ascend'?0:1;
    if(field === 'certificationTime'){
      mySort = 1;
    }else if(field === 'validityTime'){
      mySort = 2;
    }else if(field === 'validityTimeB'){
      mySort = 3;
    }else if(field === 'contracts_startTime'){
      mySort = 4;
    }else if(field === 'contracts_endTime'){
      mySort = 5;
    }else if(field === 'lastTime'){
      mySort = 6;
    }else if(field === 'nextTime'){
      mySort = 7;
    }
    let memberInfo = Cookies.getJSON('jianli_memberInfo');
    let data = this.searchFormRef.getFormData();
    data.page = page;
    data.mySort = mySort;
    data.desc = desc;
    data.owner = memberInfo.realName;

    let {columns} = this.state;
    // 重置排序字段，一次只能有一个
    columns.map(col=>{
      if(col.dataIndex === field){
        col.sortOrder = order;
      }else{
        col.sortOrder = false
      }
    })
    this.setState({
      loading:true,
      field,
      order
    })
    Api.cms_resume_getResumes(data)
    .then(({data})=>{
      setTimeout(()=>{
        let dataSource = [],totalCount=0;
        if(data.code == '200'){
          message.success('查询成功');
          let { list } = data.data;
          dataSource = this.buildDataSource(list);
          totalCount = data.data.totalCount;
        }else{
          message.error('服务器异常');
        }
        this.setState({
          loading:false,
          current:page,
          totalCount,
          dataSource
        })
      },300)
    })
  }

  resetForm(){
    this.searchFormRef.resetForm();
  }

  buildDataSource(list){
    let dataSource = [];
    dataSource = list.map((resume, index) => {
      let { credentialsList } = resume;
      let children = [];
      if(!credentialsList || credentialsList.length == 0){
        credentialsList = []
      }
      let credentialsRes = {
        index:index+1,
        key:resume.id,
        id:resume.id,
        realName:resume.realName,
        level:resume.level,
        nextTime:this.formatDate(resume.nextTime),
        lastTime:this.formatDate(resume.lastTime),
        followRemark:resume.followRemark,
      };
      let credentials = credentialsList.shift();
      children = credentialsList.map((credentials,index)=>{
        // 证书字段相关
        return {
          index:index+1,
          key:credentials.id,
          id:resume.id,
          credentialsType:credentials.credentialsType,
          credentialsMajor:credentials.credentialsMajor,
          credentialsLevel:credentials.credentialsLevel,
          certificationAuthority:credentials.certificationAuthority,
          certificationTime:this.formatDate(credentials.certificationTime),
          registeredPlace:credentials.registeredPlace,
          validityTime:this.formatDate(credentials.validityTime),
          otherB:credentials.otherB,
          validityTimeB:this.formatDate(credentials.validityTimeB),
          single:credentials.single,
          net:credentials.net
        }
      })
      if(credentials){
        credentialsRes = {
          ...credentialsRes,
          credentialsType:credentials.credentialsType,
          credentialsMajor:credentials.credentialsMajor,
          credentialsLevel:credentials.credentialsLevel,
          certificationAuthority:credentials.certificationAuthority,
          certificationTime:this.formatDate(credentials.certificationTime),
          registeredPlace:credentials.registeredPlace,
          validityTime:this.formatDate(credentials.validityTime),
          otherB:credentials.otherB,
          validityTimeB:this.formatDate(credentials.validityTimeB),
          single:credentials.single,
          net:credentials.net
        }
      }
      if(children && children.length>0){
        credentialsRes.children = children.length?children:null
      }
      return credentialsRes;
    });
    return dataSource
  }

  formatDate(dateStr){
    if(dateStr){
      return moment(dateStr).format('YYYY-MM-DD')
    }else{
      return ''
    }
  }
  /**分页 */
  pageChange = (page)=>{
    this.onSearch(page)
  }

  /**显示详情弹出层 */
  showDetailModal(record){
    this.resumesModalRef.current.show('info',record.id)
  }

  handleTableChange = (pagination, filters, sorter)=>{
    let {field,order} = sorter;
    order = order?order:'ascend';
    this.onSearch(pagination.current,field,order)
  }

  componentDidMount(){
    this.onSearch(1);
  }

  render(){
    let {loading,disableList} = this.state;
    return(
      <div className={style.viewport}>
        {/* 查询表单 */}
        <div>
          <SearchForm  wrappedComponentRef={(form) => this.searchFormRef = form} disableList={disableList}/>
        </div>
        {/* 操作区域 */}
        <div className={style['btn-warp']}>
          <Button loading={loading} onClick={()=>this.onSearch(1)}>查询</Button>
          <Button onClick={()=>this.resetForm()}>重置查询条件</Button>
        </div>
        {/* 查询列表区域 */}
        <div>
          <Table
            bordered={true}
            scroll={{ y: 600 }}
            pagination={{
              pageSize: 15,
              showQuickJumper:true,
              current: this.state.current,
              total: this.state.totalCount
            }}
            onChange={this.handleTableChange}
            onRow={record => {
              return {
                onDoubleClick: () => {
                  this.showDetailModal(record);
                }
              };
            }}
            loading={this.state.loading}
            columns={this.state.columns}
            dataSource={this.state.dataSource} />
        </div>
        {/* 新增简历弹出层 */}
        <ResumesModal ref={this.resumesModalRef} />
      </div>
    )
  }
}

export default MyOwnerResumesListPage;
