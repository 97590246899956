import Axios from "axios";
import moment from "moment";
import lodash from "lodash";

// {
//   "diplomaLevel": "string",
//   "diplomaMajor": "string",
//   "diplomaTimeEnd": "2019-03-12T14:07:31.926Z",
//   "diplomaTimeStart": "2019-03-12T14:07:31.927Z",
//   "page": 0,
//   "pageSize": 0,
//   "realName": "string",
//   "resumeId": 0,
//   "schoolName": "string"
// }

function cms_diploma_getByParam(params){
  return Axios.post('/cms/diploma/getByParam',{
    ...params
  })
}

function cms_diploma_delete(id){
  return Axios.get('cms/diploma/delete',{
    params:{
      id
    }
  })
}

function cms_diploma_getById(id){
  return Axios.get('cms/diploma/getById',{
    params:{
      id
    }
  })
}
// 编辑保存都用这个
function cms_diploma_save(diplomaDetail){
  return Axios.post('cms/diploma/save',{
    ...diplomaDetail
  })
}

export default {
  cms_diploma_getByParam,
  cms_diploma_delete,
  cms_diploma_getById,
  cms_diploma_save
}