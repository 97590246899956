import React from 'react';

import {
  Input,
  InputNumber,
  Select,
  Form,
  Button,
  message
} from 'antd';

import lodash from 'lodash';

import style from './BaseInfo.less';

import ResumeApi from '../../api/ResumeApi';

/**
 * 个人简历的基础信息展示组件
 */
class ResumesBaseInfo extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      // add,info,edit 3种情况
      type:props.defaultType,
      // 处理联系人，联动检查
      checkConnect:true,
      // 保存 进度条
      saveLoading:false,
      // 是否触发了 编辑按钮
      needEdit:false,
      form:{
        // 标记信息
        id:props.defaultId,

        // 第一组
        realName:'',
        sex:'',
        age:0,
        idcard:'',
        insurance:'',

        // 第二组
        messagePhone:'',
        idcardHad:'否',
        credentialsLevel:'',
        diplomaLevel:'',

        // 第三组
        follower:'',
        telephone:'',
        qq:'',
        weiXin:'',
        email:'',
        phone:'',
        address:'',

        // 第四组
        level:'C',
        dealer:'',
        owner:'',
        enter:'',
        invalider:''
      }
    }
  }
  // 处理联合校验的问题
  connectChange(e,type){
    let {getFieldsValue,validateFields} = this.props.form;
    let fields = ['telephone','qq','weiXin','email'];
    let values = getFieldsValue(fields);
    if(e){
      values[type] = e.target.value;
    }
    let checkConnect = true;
    lodash.forEach(values,(val,key)=>{
      if(val && val.length>0){
        checkConnect = false
      }
    })
    this.setState({
      checkConnect
    })
    if(!checkConnect){
      setTimeout(()=>{
        validateFields(fields)
      },5)
    }
  }
  // 保存简历信息
  save(){
    let {validateFields} = this.props.form;
    validateFields((err, values)=>{
      // 校验通过
      if(!err){
        this.setState({
          saveLoading:true
        })
        values.id = this.state.form.id;
        ResumeApi.cms_resume_save(values)
        .then(({data})=>{
          if(data.code == '200'){
            message.success('简历保存成功');
            this.setState({
              form:data.data,
              saveLoading:false,
              type:'info',
              needEdit:false
            })
            this.props.onSave(data.data.id)
          }else{
            message.error(data.message || '简历保存失败')
          }
        })
      }
    })
  }
  // 1. 新增加的时候，本地化， 2. 编辑的时候同步数据
  setLevel(level){
    let {form,needEdit} = this.state;
    if(form.id && !needEdit){
      ResumeApi.cms_resume_updateResumeLev(form.id,level)
      .then(({data})=>{
        if(data.code == '200'){
          message.success('简历等级修改成功')
          this.setState({
            form:data.data
          })
          this.props.updateLevel(level);
        }else{
          message.error(data.message || '简历等级设置失败')
        }
      })
    }else{
      this.setState({
        form:{
          ...form,
          level
        }
      })
    }
  }

  // 启动编辑
  edit(){
    this.setState({
      needEdit:true,
      type:'edit'
    })
  }

  componentDidMount(){
    let {form} = this.state;
    if(form.id){
      ResumeApi.cms_resume_getById(form.id)
      .then(({data})=>{
        if(data.code == '200'){
          this.setState({
            form:data.data
          })
          setTimeout(()=>{
            this.connectChange();
          },5)
        }else{
          message.error(data.message || '简历信息查询失败')
        }
      })
    }
  }

  // 显示不同的按钮
  renderBtn(){
    let {form,needEdit,saveLoading} = this.state;
    // 有id，并且没有选择编辑，显示编辑按钮
    if(form.id && !needEdit){
      return <Button onClick={()=>this.edit()}>编辑</Button>
    }else if(form.id && needEdit){
      return <Button onClick={()=>this.save()} loading={saveLoading}>保存</Button>
    }else if(!form.id){
      return <Button onClick={()=>this.save()} loading={saveLoading}>保存</Button>
    }
  }

  render(){
    let {getFieldDecorator} = this.props.form;
    let {type,form,checkConnect} = this.state;
    
    let disabled = type=='info';
    const LevelABtn = (
      <Button className={style['level-btn']} onClick={()=>this.setLevel('A')} >设置为A级</Button>
    )
    const LevelBBtn = (
      <Button className={style['level-btn']} onClick={()=>this.setLevel('B')}>设置为B级</Button>
    )
    const LevelCBtn = (
      <Button className={style['level-btn']} onClick={()=>this.setLevel('C')}>设置为C级</Button>
    )
    const LevelDBtn = (
      <Button className={style['level-btn']} onClick={()=>this.setLevel('D')}>设置为D级</Button>
    )

    return(
      <div className={style.page}>
        <div className={style.header}>
          <h2>简历基础信息</h2>
          {/* 按钮区域 */}
          <div className={style.btn}>
          {
            this.renderBtn()
          }
          </div>
        </div>
        <Form className={style.form} layout="inline">
          {/* 基本信息 */}
          <div className={style.block}>
            <h2>基本信息</h2>
            <Form.Item label={'姓名'}>
            {
              getFieldDecorator('realName',{
                initialValue:form.realName
              })(
                <Input placeholder={'请输入姓名'} disabled={disabled}></Input>
              )
            }
            </Form.Item>
            <Form.Item label={'性别'}>
            {
              getFieldDecorator('sex',{
                initialValue:form.sex
              })(
                <Select disabled={disabled}>
                  <Select.Option value={''}>未知</Select.Option>
                  <Select.Option value={'男'}>男</Select.Option>
                  <Select.Option value={'女'}>女</Select.Option>
                </Select>
              )
            }
            </Form.Item>
            <Form.Item label={'年龄'}>
            {
              getFieldDecorator('age',{
                initialValue:form.age
              })(
                <InputNumber min={18} max={80} disabled={disabled}/>
              )
            }
            </Form.Item>
            <Form.Item label={'身份证号'}>
            {
              getFieldDecorator('idcard',{
                initialValue:form.idcard
              })(
                <Input placeholder={'请输入身份证号'} disabled={disabled}></Input>
              )
            }
            </Form.Item>
            <Form.Item label={'社保情况'}>
            {
              getFieldDecorator('insurance',{
                initialValue:form.insurance
              })(
                <Input placeholder={'请输入社保情况'} disabled={disabled}></Input>
              )
            }
            </Form.Item>
          </div>
          {/* 信息采集情况 */}
          <div className={style.block}>
            <h2>信息采集情况</h2>
            <Form.Item label={'信息采集电话'}>
            {
              getFieldDecorator('messagePhone',{
                initialValue:form.messagePhone
              })(
                <Input placeholder={'请输入信息采集电话'} disabled={disabled}></Input>
              )
            }
            </Form.Item>
            <Form.Item label={'身份证是否已采集'}>
            {
              getFieldDecorator('idcardHad',{
                initialValue:form.idcardHad
              })(
                <Select disabled={disabled}>
                  <Select.Option value={'是'}>是</Select.Option>
                  <Select.Option value={'否'}>否</Select.Option>
                </Select>
              )
            }
            </Form.Item>
            <Form.Item label={'证书信息等级'}>
            {
              getFieldDecorator('credentialsLevel',{
                initialValue:form.credentialsLevel
              })(
                <Select disabled={disabled}>
                  <Select.Option value={''}>未知</Select.Option>
                  <Select.Option value={'A'}>A</Select.Option>
                  <Select.Option value={'B'}>B</Select.Option>
                  <Select.Option value={'C'}>C</Select.Option>
                  <Select.Option value={'D'}>D</Select.Option>
                  <Select.Option value={'E'}>E</Select.Option>
                </Select>
              )
            }
            </Form.Item>
            <Form.Item label={'毕业证信息等级'}>
            {
              getFieldDecorator('diplomaLevel',{
                initialValue:form.diplomaLevel
              })(
                <Select disabled={disabled}>
                  <Select.Option value={''}>未知</Select.Option>
                  <Select.Option value={'A'}>A</Select.Option>
                  <Select.Option value={'B'}>B</Select.Option>
                  <Select.Option value={'C'}>C</Select.Option>
                  <Select.Option value={'D'}>D</Select.Option>
                  <Select.Option value={'E'}>E</Select.Option>
                </Select>
              )
            }
            </Form.Item>
            <Form.Item label={'履历情况'}>
            {
              getFieldDecorator('workRecord',{
                initialValue:form.workRecord
              })(
                <Input placeholder={'请输入履历情况'} disabled={disabled}></Input>
              )
            }
            </Form.Item>
            <Form.Item label={'业绩情况'}>
            {
              getFieldDecorator('dealDesc',{
                initialValue:form.dealDesc
              })(
                <Input placeholder={'请输入业绩情况'} disabled={disabled}></Input>
              )
            }
            </Form.Item>
          </div>
          {/* 联系方式 */}
          <div className={style.block}>
            <h2>联系方式</h2>
            <Form.Item label={'联系人'}>
            {
              getFieldDecorator('follower',{
                initialValue:form.follower
              })(
                <Input placeholder={'请输入联系人'} disabled={disabled}></Input>
              )
            }
            </Form.Item>
            <Form.Item label={'电话'}>
            {
              getFieldDecorator('telephone',{
                initialValue:form.telephone,
                rules:[
                  {
                    required:checkConnect,
                    whitespace:true,
                    message: '请输入联系方式',
                  }
                ]
              })(
                <Input placeholder={'请输入电话'} disabled={disabled} onChange={(e)=>this.connectChange(e,'telephone')} />
              )
            }
            </Form.Item>
            <Form.Item label={'QQ'}>
            {
              getFieldDecorator('qq',{
                initialValue:form.qq,
                rules:[
                  {
                    required:checkConnect,
                    whitespace:true,
                    message: '请输入联系方式',
                  }
                ]
              })(
                <Input placeholder={'请输入QQ'} disabled={disabled} onChange={(e)=>this.connectChange(e,'qq')} />
              )
            }
            </Form.Item>
            <Form.Item label={'微信'}>
            {
              getFieldDecorator('weiXin',{
                initialValue:form.weiXin,
                rules:[
                  {
                    required:checkConnect,
                    whitespace:true,
                    message: '请输入联系方式',
                  }
                ]
              })(
                <Input placeholder={'请输入微信'} disabled={disabled} onChange={(e)=>this.connectChange(e,'weiXin')}/>
              )
            }
            </Form.Item>
            <Form.Item label={'Email'}>
            {
              getFieldDecorator('email',{
                initialValue:form.email,
                rules:[
                  {
                    required:checkConnect,
                    whitespace:true,
                    message: '请输入联系方式',
                  }
                ]
              })(
                <Input placeholder={'请输入Email'} disabled={disabled} onChange={(e)=>this.connectChange(e,'email')}/>
              )
            }
            </Form.Item>
            <Form.Item label={'其他联系方式'}>
            {
              getFieldDecorator('phone',{
                initialValue:form.phone
              })(
                <Input placeholder={'请输入其他联系方式'} disabled={disabled}></Input>
              )
            }
            </Form.Item>
            <Form.Item label={'地址'}>
            {
              getFieldDecorator('address',{
                initialValue:form.address
              })(
                <Input placeholder={'请输入地址'} disabled={disabled}></Input>
              )
            }
            </Form.Item>
          </div>
          {/* 简历信息等级 */}
          <div className={`${style.block} ${style.level}`}>
            <h2>简历信息等级</h2>
            <div>
            <Form.Item label={'当前等级'} className={style[form.level||'normal']}>
            {
              getFieldDecorator('level',{
                initialValue:form.level
              })(
                <Input disabled={true} />
              )
            }
            </Form.Item>
            <Form.Item label={'成交人'}>
            {
              getFieldDecorator('dealer',{
                initialValue:form.dealer
              })(
                <Input disabled={true} addonAfter={LevelABtn}/>
              )
            }
            </Form.Item>
            <Form.Item label={'独占人'}>
            {
              getFieldDecorator('owner',{
                initialValue:form.owner
              })(
                <Input disabled={true} addonAfter={LevelBBtn}/>
              )
            }
            </Form.Item>
            <Form.Item label={'简历录入人'}>
            {
              getFieldDecorator('enter',{
                initialValue:form.enter
              })(
                <Input disabled={true} addonAfter={LevelCBtn}/>
              )
            }
            </Form.Item>
            <Form.Item label={'无效简历设置人'}>
            {
              getFieldDecorator('invalider',{
                initialValue:form.invalider
              })(
                <Input disabled={true} addonAfter={LevelDBtn}/>
              )
            }
            </Form.Item>
            </div>
          </div>
        </Form>

      </div>
    )
  }
}


export default Form.create({
  name:'ResumesBaseInfoForm'
})(ResumesBaseInfo)