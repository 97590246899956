import Axios from "axios";
import lodash from "lodash";
import moment from "moment";

const DateFormat = "YYYY-MM-DD";
const DateFormat2 = "YYYY";
/**
 * 查询当前用户信息
 */
export function cms_resume_getResumes(params = {}) {
  let queryParams = { pageSize: 15 };
  lodash.forEach(params, (value, key) => {
    //处理全部查询到值
    if (value != -1) {
      queryParams[key] = value;
    }
    if (key === "certificationTimeStart" && !!value) {
      queryParams.certificationTimeStart = `${moment(value).format(
        DateFormat2
      )}-01-01 00:00:00`;
      queryParams.certificationTimeEnd = `${moment(value).format(
        DateFormat2
      )}-12-31 23:59:59`;
    }
    if (key === "diplomaTimeStart" && !!value) {
      queryParams.diplomaTimeStart = `${moment(value).format(
        DateFormat2
      )}-01-01 00:00:00`;
      queryParams.diplomaTimeEnd = `${moment(value).format(
        DateFormat2
      )}-12-31 23:59:59`;
    }
  });
  return Axios.post("/cms/resume/getResumes", queryParams);
}
/**
 * 新增/更新简历
 */
export function cms_resume_add(params = {}) {
  return Axios.post("/cms/resume/save", params);
}
/**
 * 上传错误简历查询
 */
export function cms_resume_err(params = {}) {
  return Axios.post("/cms/resume/getUploadFailResumes", params);
}
/**
 * 查询员工列表数据
 */
export function cms_member_get(params = {}) {
  return Axios.post("/cms/member/getMembers", params);
}
/**
 * 新增/修改员工信息
 */
export function cms_bermem_save(params = {}) {
  return Axios.post("/cms/member/save", params);
}

/**
 * 删除员工信息
 */
export function cms_bermem_delete(id) {
  return Axios.get("/cms/member/delete", {
    params: {
      id
    }
  });
}

export default {
  cms_member_get,
  cms_resume_err,
  cms_resume_add,
  cms_bermem_save,
  cms_bermem_delete,
  cms_resume_getResumes
};
