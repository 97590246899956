import Axios from "axios";

/**
 * 查询当前用户信息
 */
export function cms_member_view() {
  return Axios.get("/cms/member/view");
}

/**
 * 登录
 * @param
 * @example {
 *  "machineCode": "string",
 *  "pwd": "string",
 *  "userName": "string"
 * }
 */
export function cms_member_login(userName,pwd) {
  return Axios.post("/cms/member/login", {
    machineCode: "string",
    userName,
    pwd
  });
}

export function cms_member_logout() {
  return Axios.get("/cms/member/logout");
}

export default {
  cms_member_view,
  cms_member_login,
  cms_member_logout
};
