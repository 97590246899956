import React from 'react';

import {
  Form, Icon, Input, Button,message
} from 'antd';

import style from './LoginPage.less';

import MemberApi from '../api/MemberApi'


class LoginPage extends React.Component {

  handleSubmit(e){
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        MemberApi.cms_member_login(values.userName,values.pwd)
        .then(({data})=>{
          if(data.code === '200'){
            this.props.history.push({
              pathname:'/list'
            })
          }else{
            message.error(data.message)
          }
        })
        .catch(error=>{
          message.error(error.message)
        })
      }
    });
  }
  render(){
    const { getFieldDecorator } = this.props.form;
    return(
      <div className={style.viewport}>
        <div className={style.content}>
          <h1>
            登录
          </h1>
          <Form onSubmit={(e)=>this.handleSubmit(e)}>
            <Form.Item>
              {getFieldDecorator('userName', {
                rules: [{ required: true, message: '请输入用户名!' }],
              })(
                <Input 
                prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} 
                size={'large'}
                placeholder="用户名" />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('pwd', {
                rules: [{ required: true, message: '请输入密码!' }],
              })(
                <Input
                type={'password'}
                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                size={'large'}
                placeholder="密码" />
              )}
            </Form.Item>
            <Form.Item>
              <Button
                size={'large'}
                block
                type="primary" 
                htmlType="submit">
              登录</Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    )
  }
}

export default Form.create()(LoginPage);