import React from 'react';
import moment from 'moment';
import {
  Button,
  Table,
  message,
  Upload,
  Icon,
  Modal
} from 'antd';

import style from './ResumesListPage.less';

import SearchForm from '../component/SearchForm';
import Api from '../api';

import {errorColumsRes} from './dataTableColumns';


const Colums = [
  {
    title:'序号',
    dataIndex:'index',
    width: '3%'
  },
  {
    title: '姓名',
    dataIndex: 'realName',
    width: '5%'
  }, {
    title: '证书种类',
    dataIndex: 'credentialsType',
    width: '5%'
  }, {
    title: '证书专业',
    dataIndex: 'credentialsMajor',
    width: '5%'
  }, {
    title: '证书等级',
    dataIndex: 'credentialsLevel',
    width: '5%'
  }, {
    title: '发证地',
    dataIndex: 'certificationAuthority',
    width: '3%'
  }, {
    title: '发证时间',
    dataIndex: 'certificationTime',
    width: '8%',
    sorter: true
  }, {
    title: '现注册地',
    dataIndex: 'registeredPlace',
    width: '5%'
  }, {
    title: '证书有效期',
    dataIndex: 'validityTime',
    width: '8%',
    sorter: true
  }, {
    title: 'B证',
    dataIndex: 'otherB',
    width: '3%'
  }, {
    title: 'B证有效期',
    dataIndex: 'validityTimeB',
    width: '8%',
    sorter: true
  }, {
    title: '是否为单证',
    dataIndex: 'single',
    width: '6%'
  },{
    title: '三证一表/网查结果',
    dataIndex: 'net',
  }, {
    title: '下次联系日期',
    dataIndex: 'nextTime',
    width: '8%',
    sorter: true
  },{
    title: '上次联系日期',
    dataIndex: 'lastTime',
    width: '8%',
    sorter: true
  },  {
    title: '上次联系情况',
    dataIndex: 'followRemark',
    width: '10%'
  }
]


const UploadProps = {
  name: "file",
  action: "/cms/resume/upload",
  onChange(info) {
    if (info.file.status !== "uploading") {
    }
    if (info.file.status === "done") {
      let arr = info.file.response.message.split(",");
      let successData = arr[1].split(":")[1];
      let failData = arr[2].split(":")[1];
      Modal.confirm({
        title: "上传信息",
        content: `上传成功${successData}个，上传失败${failData}个；`,
        okText: "确认",
        cancelText: "取消"
      });
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  }
};

// 新增简历弹出层
import ResumesModal from '../component/Resumes/Modal';

class ResumesListPage extends React.Component {
  constructor(props){
    super(props);
    this.searchFormRef = React.createRef();
    // 新增简历弹出层 Ref
    this.resumesModalRef = React.createRef();

    let localColums = Colums.map(col=>{
      return {
        ...col,
        align: 'center'
      }
    })

    this.state = {
      current:1,
      totalCount:0,
      loading:false,
      dataSource:[],
      columns:localColums,
      errorColumsRes,
      failDataVisible:false,
      errDataCurrent:1,
      errDataTotalCount:0,
      errDataLoading:false,
      errDataSource:[],
      disableList:[
        'validityTimeStart',
        'validityTimeBStart'
      ],
      field:null,
      order:false
    }
  }
  onSearch(page,field,order){
    // mySort:排序字段:1发证时间，2证书有效期，3B证有效期，4合同开始时间，5合同结束时间，6上次联系时间，7下次联系时间 
    // desc:升序0/降序1 
    field = field || this.state.field;
    order = order || this.state.order;
    let mySort,desc;
    // 未设置排序字段
    if(!field){
      order = 'descend'
    }
    desc = order === 'ascend'?0:1;
    if(field === 'certificationTime'){
      mySort = 1;
    }else if(field === 'validityTime'){
      mySort = 2;
    }else if(field === 'validityTimeB'){
      mySort = 3;
    }else if(field === 'contracts_startTime'){
      mySort = 4;
    }else if(field === 'contracts_endTime'){
      mySort = 5;
    }else if(field === 'lastTime'){
      mySort = 6;
    }else if(field === 'nextTime'){
      mySort = 7;
    }

    let data = this.searchFormRef.getFormData();
    data.page = page;
    data.mySort = mySort;
    data.desc = desc;

    let {columns} = this.state;
    // 重置排序字段，一次只能有一个
    columns.map(col=>{
      if(col.dataIndex === field){
        col.sortOrder = order;
      }else{
        col.sortOrder = false
      }
    })
    this.setState({
      loading:true,
      field,
      order
    })
    Api.cms_resume_getResumes(data)
    .then(({data})=>{
      setTimeout(()=>{
        let dataSource = [],totalCount=0;
        if(data.code == '200'){
          message.success('查询成功');
          let { list } = data.data;
          dataSource = this.buildDataSource(list);
          totalCount = data.data.totalCount;
        }else{
          message.error('服务器异常');
        }
        this.setState({
          loading:false,
          current:page,
          totalCount,
          dataSource
        })
      },1000)
    })
  }
  resetForm(){
    this.searchFormRef.resetForm();
  }
  buildDataSource(list){
    let dataSource = [];

    dataSource = list.map((resumeItem,index)=>{
      let { credentialsList } = resumeItem;
      if(!credentialsList || credentialsList.length == 0){
        credentialsList = []
      }
      let credentialsRes = {
        index:index+1,
        key:resumeItem.id,
        id:resumeItem.id,
        realName:resumeItem.realName,
        level:resumeItem.level,
        nextTime:this.formatDate(resumeItem.nextTime),
        lastTime:this.formatDate(resumeItem.lastTime),
        followRemark:resumeItem.followRemark,
      };
      let credentials = credentialsList.shift();
      let children = credentialsList.map((credentials,index)=>{
        return {
          key:credentials.id,
          id:resumeItem.id,
          index:index+1,
          credentialsType:credentials.credentialsType,
          credentialsMajor:credentials.credentialsMajor,
          credentialsLevel:credentials.credentialsLevel,
          certificationAuthority:credentials.certificationAuthority,
          certificationTime:this.formatDate(credentials.certificationTime),
          registeredPlace:credentials.registeredPlace,
          validityTime:this.formatDate(credentials.validityTime),
          otherB:credentials.otherB,
          validityTimeB:this.formatDate(credentials.validityTimeB),
          single:credentials.single,
          net:credentials.net
        }
      });

      if(credentials){
        credentialsRes = {
          ...credentialsRes,
          credentialsType:credentials.credentialsType,
          credentialsMajor:credentials.credentialsMajor,
          credentialsLevel:credentials.credentialsLevel,
          certificationAuthority:credentials.certificationAuthority,
          certificationTime:this.formatDate(credentials.certificationTime),
          registeredPlace:credentials.registeredPlace,
          validityTime:this.formatDate(credentials.validityTime),
          otherB:credentials.otherB,
          validityTimeB:this.formatDate(credentials.validityTimeB),
          single:credentials.single,
          net:credentials.net
        }
      }

      if(children && children.length>0){
        credentialsRes.children = children
      }
      return credentialsRes;
    })
    return dataSource
  }
  formatDate(dateStr){
    if(dateStr){
      return moment(dateStr).format('YYYY-MM-DD')
    }else{
      return ''
    }
  }
  /**上传错误数据分页 */
  errDataPageChange(page){
    this.setState({
      errDataLoading: true
    });
    let queryParams = {
      page,
      "pageSize": 15
    };
    Api.cms_resume_err(queryParams)
    .then(res => {
      let { data } = res;
      
      if (data.code == "200") {
        this.loadErrorData(data.data, page);
      } else {
        message.error(data.message);
      }
      this.setState({
        errDataLoading: false
      });
    })
    .catch(err => {
      message.error(err.message);
      this.setState({
        errDataLoading: false
      });
    });
  }

  // 删除上传失败记录
  delUploadFailResumes(){
    Api.cms_resume_delUploadFailResumes()
    .then(res=>{
      message.success('数据清空成功');
      this.errDataPageChange(1);
    })
  }

  /**上传错误数据处理 */
  loadErrorData = (data, current) => {
    // 简历信息
    let { list, totalCount } = data;
    let errDataSource = [];
    list.map((resume, index1) => {
      let first = {};
      first.key = resume.id;
      first.realName = resume.realName||'';
      first.age = resume.age||'';
      first.address = resume.address||'';
      first.email = resume.email||'';
      first.qq = resume.qq||'';
      first.telephone = resume.telephone||'';
      first.phone = resume.phone||'';
      first.follower = resume.follower||'';
      first.idcard = resume.idcard||'';
      first.level = resume.level||'';
      first.remark = resume.remark||'';
      first.createTime = resume.createTime;
      errDataSource.push(first);
    });
    this.setState({
      errDataSource,
      errDataTotalCount:totalCount,
      errDataCurrent: current,
      errDataLoading: false
    });
  };

  /**显示详情弹出层 */
  showDetailModal(record){
    this.resumesModalRef.current.show('info',record.id,record.level)
  }
  /**显示错误数据列表弹出层 */
  showModal() {
    this.errDataPageChange(1);
    this.setState({
      failDataVisible: true
    });
  }
  /**关闭错误数据列表弹出层 */
  modalClose() {
    this.setState({
      failDataVisible: false
    });
  }

  handleTableChange = (pagination, filters, sorter)=>{
    let {field,order} = sorter;
    order = order?order:'ascend';
    this.onSearch(pagination.current,field,order)
  }

  render(){
    let {loading,disableList} = this.state;
    return(
      <div className={style.viewport}>
        {/* 查询表单 */}
        <div>
          <SearchForm  wrappedComponentRef={(form) => this.searchFormRef = form} disableList={disableList}/>
        </div>
        {/* 操作区域 */}
        <div className={style['btn-warp']}>
          <Button loading={loading} onClick={()=>this.onSearch(1)}>查询</Button>
          <Button onClick={()=>this.resetForm()}>重置查询条件</Button>
          <Button onClick={()=>{this.resumesModalRef.current.show('add')}}>新增简历</Button>
          <Button onClick={() => {this.showModal()}}>上传失败数据查询</Button>
          <Upload {...UploadProps}>
            <Button><Icon type="upload" />简历上传</Button>
          </Upload>
        </div>
        {/* 查询列表区域 */}
        <div>
          <Table
            bordered={true}
            scroll={{ y: 600 }}
            pagination={{
              pageSize: 15,
              showQuickJumper:true,
              current: this.state.current,
              total: this.state.totalCount
            }}
            onRow={record => {
              return {
                onDoubleClick: () => {
                  this.showDetailModal(record);
                }
              };
            }}
            loading={this.state.loading}
            columns={this.state.columns}
            onChange={this.handleTableChange}
            dataSource={this.state.dataSource} />
        </div>
        {/* 弹层区域 */}
        <Modal
          onCancel={() => {this.modalClose()}}
          className="resumes-modal"
          title="上传失败简历列表"
          width={"90%"}
          visible={this.state.failDataVisible}
          footer={null}
        >
          <div>
            <Button onClick={()=>this.delUploadFailResumes()}>清空上传错误数据</Button>
          </div>
          <Table
            className={style['upload-fail']}
            bordered={true}
            pagination={{
              pageSize: 15,
              current: this.state.errDataCurrent,
              total: this.state.errDataTotalCount,
              onChange: (page)=>this.errDataPageChange(page)
            }}
            loading={this.state.errDataLoading}
            columns={this.state.errorColumsRes}
            dataSource={this.state.errDataSource}
          />
        </Modal>
        {/* 新增简历弹出层 */}
        <ResumesModal ref={this.resumesModalRef} />
      </div>
    )
  }
}

export default ResumesListPage;
