import React from 'react';
import Cookies from 'js-cookie';

import {
  Form,
  Select,
  Row,
  Input,
  DatePicker
} from 'antd';
import style from './SearchForm.less';

const FormItemList = [
  {
    id:'credentialsType',
    label:'证书种类',
    formItemType:'select',
    optionList:[
      {
        value:'-1',
        text:'全部'
      },
      {
        value:'工程师证',
        text:'工程师证'
      },
      {
        value:'建造师证',
        text:'建造师证'
      },
      {
        value:'其他证书',
        text:'其他证书'
      }
    ],
    row:1,
    options:{
      initialValue:'-1'
    }
  },{
    id:'credentialsMajor',
    label:'证书专业',
    formItemType:'input',
    placeholder:'请输入证书专业',
    row:1,
    options:{}
  },{
    id:'credentialsLevel',
    label:'证书等级',
    formItemType:'select',
    optionList:[
      {
        value:'-1',
        text:'全部'
      },
      {
        value:'中级',
        text:'中级'
      },
      {
        value:'高级',
        text:'高级'
      },
      {
        value:'正高级',
        text:'正高级'
      },
      {
        value:'一级',
        text:'一级'
      },
      {
        value:'二级',
        text:'二级'
      },
      {
        value:'三级',
        text:'三级'
      }
    ],
    row:1,
    options:{
      initialValue:'-1'
    }
  },{
    id:'certificationAuthority',
    label:'发证地',
    formItemType:'input',
    placeholder:'请输入发证地',
    row:1,
    options:{}
  },{
    id:'certificationTimeStart',
    label:'发证日期',
    formItemType:'datepicker',
    placeholder:'请选择发证日期',
    format:'YYYY',
    mode:'year',
    row:1,
    options:{
    }
  },{
    id:'single',
    label:'单证',
    formItemType:'select',
    optionList:[
      {
        value:'-1',
        text:'全部'
      },
      {
        value:'是',
        text:'是'
      },
      {
        value:'否',
        text:'否'
      }
    ],
    row:1,
    options:{
      initialValue:'-1'
    }
  },{
    id:'otherB',
    label:'B证',
    formItemType:'select',
    optionList:[
      {
        value:'-1',
        text:'全部'
      },
      {
        value:'有',
        text:'有'
      },
      {
        value:'无',
        text:'无'
      }
    ],
    row:1,
    options:{
      initialValue:'-1'
    }
  },{
    id:'diplomaTimeStart',
    label:'毕业日期',
    formItemType:'datepicker',
    placeholder:'请选择毕业日期',
    format:'YYYY',
    mode:'year',
    row:2,
    options:{
      // initialValue:moment(),
    }
  },{
    id:'diplomaMajor',
    label:'毕业专业',
    formItemType:'input',
    placeholder:'请输入毕业专业',
    row:2,
    options:{}
  },{
    id:'diplomaLevel',
    label:'学历',
    formItemType:'select',
    optionList:[
      {
        value:'-1',
        text:'全部'
      },
      {
        value:'中专',
        text:'中专'
      },
      {
        value:'大专',
        text:'大专'
      },
      {
        value:'本科',
        text:'本科'
      },
      {
        value:'硕士',
        text:'硕士'
      },
      {
        value:'博士',
        text:'博士'
      }
    ],
    row:2,
    options:{
      initialValue:'-1'
    }
  },{
    id:'level',
    label:'信息等级',
    formItemType:'select',
    optionList:[
      {
        value:'-1',
        text:'全部'
      },
      {
        value:'A',
        text:'A级'
      },
      {
        value:'B',
        text:'B级'
      },
      {
        value:'C',
        text:'C级'
      },
      {
        value:'D',
        text:'D级'
      }
    ],
    row:2,
    options:{
      initialValue:'C'
    }
  },{
    id:'realName',
    label:'姓名',
    formItemType:'input',
    placeholder:'请输入姓名',
    row:3,
    options:{}
  },{
    id:'keyWords',
    label:'联系方式',
    formItemType:'input',
    placeholder:'请输入方式',
    row:3,
    options:{}
  },{
    id:'nextTimeStart',
    label:'下次联系时间',
    formItemType:'select',
    optionList:[
      {
        value:'-1',
        text:'全部'
      },
      {
        value:'1',
        text:'10天内'
      },
      {
        value:'2',
        text:'1个月内'
      },
      {
        value:'3',
        text:'2个月内'
      }
    ],
    row:3,
    options:{
      initialValue:'-1'
    }
  },{
    id:'validityTimeStart',
    label:'证书有效期',
    formItemType:'select',
    optionList:[
      {
        value:'-1',
        text:'全部'
      },
      {
        value:'1',
        text:'3个月内过期'
      },
      {
        value:'2',
        text:'6个月内过期'
      },
      {
        value:'3',
        text:'已过期'
      }
    ],
    row:4,
    options:{
      initialValue:'-1'
    }
  },{
    id:'validityTimeBStart',
    label:'B证有效期',
    formItemType:'select',
    optionList:[
      {
        value:'-1',
        text:'全部'
      },
      {
        value:'1',
        text:'3个月内过期'
      },
      {
        value:'2',
        text:'6个月内过期'
      },
      {
        value:'3',
        text:'已过期'
      }
    ],
    row:4,
    options:{
      initialValue:'-1'
    }
  }
]
class SearchForm extends React.Component {
  constructor(props){
    super(props);
    let disableList = props.disableList || [];
    let formItemList = FormItemList.filter(item=>{
      return !disableList.includes(item.id)
    })
    this.state = {
      formItemList:formItemList,
      disableList:[]
    }
  }

  // 处理date-picker mode=year 的时候的bug
  onPanelChange(date,id){
    let {setFieldsValue} = this.props.form;
    setFieldsValue({
      [id]:date
    })
  }
  getFormData(){
    let {getFieldsValue} = this.props.form;
    return getFieldsValue();
  }
  renderFormItem(getFieldDecorator,item){
    let {formItemType} = item;
    // 渲染 select组件
    if(formItemType === 'select'){
      if(item.id === 'level'){
        let memberInfo = Cookies.getJSON('jianli_memberInfo') || {};
        return getFieldDecorator(item.id,item.options)(<Select>
          {
            item.optionList.filter(option=>{
              // 普通员工
              if(memberInfo.level == 1){
                return option.value != -1&&option.value != 'A'&&option.value != 'B'
              }else{
                return option
              }
            }).map(option=>(
              <Select.Option key={option.value} value={option.value}>{option.text}</Select.Option>
            ))
          }
        </Select>)
      }else{
        return getFieldDecorator(item.id,item.options)(<Select>
          {
            item.optionList.map(option=>(
              <Select.Option key={option.value} value={option.value}>{option.text}</Select.Option>
            ))
          }
        </Select>)
      }
    }else if(formItemType === 'input'){
      return getFieldDecorator(item.id,item.options)(<Input placeholder={item.placeholder}/>)
    }else if(formItemType === 'datepicker'){
      // item.options.initialValue = moment();
      return getFieldDecorator(item.id,item.options)(<DatePicker allowClear={true} mode={item.mode} format={item.format} onPanelChange={(date)=>this.onPanelChange(date,item.id)} placeholder={item.placeholder}/>)
    }
  }

  //resetFields
  resetForm(){
    let {resetFields} = this.props.form;
    resetFields();
  }

  render(){
    let {getFieldDecorator} = this.props.form;
    let {formItemList} = this.state;
    return(
      <div className={style.viewport}>
        <Form layout={'inline'}>
          {/* 第一行 */}
          <div>
            {
              formItemList.filter(item=>item.row === 1 ).map((item,index)=>{
                return(
                  <Form.Item label={item.label} key={index}>
                    {
                      this.renderFormItem(getFieldDecorator,item)
                    }
                  </Form.Item>
                )
              })
            }
          </div>
          {/* 第二行 */}
          <div>
            {
              formItemList.filter(item=>item.row === 2 ).map((item,index)=>{
                return(
                  <Form.Item label={item.label} key={index}>
                    {
                      this.renderFormItem(getFieldDecorator,item)
                    }
                  </Form.Item>
                )
              })
            }
          </div>
          {/* 第三行 */}
          <div>
            {
              formItemList.filter(item=>item.row === 3 ).map((item,index)=>{
                return(
                  <Form.Item label={item.label} key={index}>
                    {
                      this.renderFormItem(getFieldDecorator,item)
                    }
                  </Form.Item>
                )
              })
            }
          </div>
          {/* 第四行 */}
          <div>
            {
              formItemList.filter(item=>item.row === 4 ).map((item,index)=>{
                return(
                  <Form.Item label={item.label} key={index}>
                    {
                      this.renderFormItem(getFieldDecorator,item)
                    }
                  </Form.Item>
                )
              })
            }
          </div>
          {/* 第五行 */}
          <div>
            {
              formItemList.filter(item=>item.row === 5 ).map((item,index)=>{
                return(
                  <Form.Item label={item.label} key={index}>
                    {
                      this.renderFormItem(getFieldDecorator,item)
                    }
                  </Form.Item>
                )
              })
            }
          </div>
        </Form>
      </div>
    )
  }
}


export default Form.create()(SearchForm);
