import React from 'react';

import style from './List.less';


import ContractApi from '../../api/ContractApi';

import {
  Table, Button,Divider, message,Modal
} from 'antd';
import moment from 'moment';

import ContractDetailModal from './Modal';
class ContractList extends React.Component{
  constructor(props){
    super(props);
    this.contractDetailModalRef = React.createRef();
    this.state = {
      resumeId:props.id,
      dataSource:[],
      resumeId:props.resumeId
    }
    this.columns = [
      {
        title: "序号",
        dataIndex: "index",
        width: "4%",
        align: "center"
      },
      {
        title: "成交人",
        dataIndex: "dealName",
        width: "12%",
        align: "center"
      },
      {
        title: "成交时间",
        dataIndex: "dealTime",
        width: "12%",
        align: "center"
      },
      {
        title: "成交价格",
        dataIndex: "dealPrice",
        width: "12%",
        align: "center"
      },
      {
        title: "合同开始时间",
        dataIndex: "startTime",
        width: "12%",
        align: "center"
      },
      {
        title: "合同到期时间",
        dataIndex: "endTime",
        width: "12%",
        align: "center"
      },
      {
        title: "备注",
        dataIndex: "remark",
        align: "center",
      }
    ];
    
    this.columns.push({
      title: '操作',
      key: 'action',
      width: '10%',
      align: "center",
      render: (text, record) => (
        <span>
          <a href="javascript:;" onClick={()=>this.edit(record.id)} >编辑</a>
          <Divider type="vertical" />
          <a href="javascript:;" onClick={()=>this.deleted(record.id)}>删除</a>
        </span>
      ),
    })
  }
  componentDidMount(){
    this.loadData(this.state.resumeId);
  }
  // 查询简历对应的合同数据
  loadData(resumeId){
    ContractApi.cms_contract_getByParam({
      resumeId,
      page:0,
      pageSize:100
    })
    .then(({data})=>{
      if(data.code == '200'){
        let dataSource = data.data.list.map((item,index)=>{
          return {
            ...item,
            index:index+1,
            dealTime:this.formatDate(item.dealTime),
            startTime:this.formatDate(item.startTime),
            endTime:this.formatDate(item.endTime)
          }
        })
        this.setState({
          dataSource
        })
      }
    })
  }

  // 编辑合同
  edit(id){
    this.contractDetailModalRef.current.show(id,this.state.resumeId)
  }

  // 删除合同
  deleted(id){

    Modal.confirm({
      title: '确定删除吗?',
      content: '删除后将无法恢复数据，请谨慎操作！',
      okText: '确定',
      okType: 'danger',
      cancelText: '取消',
      onOk:()=>{
        ContractApi.deleteById(id)
        .then(({data})=>{
          if(data.code == '200'){
            message.success('合同删除成功');
            this.loadData(this.state.resumeId);
          }else{
            message.error('合同删除失败')
          }
        })
      }
    });
  }

  formatDate(dateStr){
    if(dateStr){
      return moment(dateStr).format('YYYY-MM-DD')
    }else{
      return ''
    }
  }

  add(){
    this.contractDetailModalRef.current.show(null,this.state.resumeId)
  }

  onOk(id){
    this.loadData(this.state.resumeId);
  }

  render(){
    let {dataSource} = this.state;
    return(
      <div className={style.page}>
        <div className={style.header}>
          <h2>合同信息</h2>
          {/* 按钮区域 */}
          <div>
           <Button onClick={()=>this.add()}>新增合同信息</Button>
          </div>
        </div>
        <Table rowKey={"id"}
          className={style.table}
          bordered={true}
          pagination={false}
          dataSource={dataSource}
          columns={this.columns}
          scroll={{ y: 200 }} />
        <ContractDetailModal ref={this.contractDetailModalRef} onOk={((id)=>this.onOk(id))}/>
      </div>
    )
  }
}

export default ContractList;