import Axios from 'axios';

/**
 * 
 * {
  "address": "string",
  "age": "string",
  "credentialsLevel": "string",
  "dealDesc": "string",
  "dealer": "string",
  "diplomaLevel": "string",
  "educate": "string",
  "email": "string",
  "enter": "string",
  "followRemark": "string",
  "follower": "string",
  "id": 0,
  "idcard": "string",
  "idcardHad": "string",
  "insurance": "string",
  "invalider": "string",
  "lastTime": "2019-03-16T14:04:18.432Z",
  "level": "string",
  "messagePhone": "string",
  "nextTime": "2019-03-16T14:04:18.432Z",
  "owner": "string",
  "phone": "string",
  "qq": "string",
  "realName": "string",
  "remark": "string",
  "sex": "string",
  "status": 0,
  "telephone": "string",
  "weiXin": "string",
  "workRecord": "string"
}
 */
function cms_resume_save(params){
  return Axios.post('/cms/resume/save',params)
}

function cms_resume_updateResumeLev(id,level){
  return Axios.post('/cms/resume/updateResumeLev',{
    id,
    level
  })
}

function cms_resume_getById(id){
  return Axios.get('/cms/resume/getById',{
    params:{
      id
    }
  })
}

export default {
  cms_resume_save,
  cms_resume_updateResumeLev,
  cms_resume_getById
}