import React from 'react';
import moment from 'moment';

import {
  Table, message ,Button
} from 'antd';

import style from './DiplomaList.less';

import FollowApi from '../api/FollowApi';

import FollowDetailModal from './FollowDetail';

const columns = [
  {
    title: '序号',
    dataIndex: 'index',
    align: "center",
    width: '5%'
  },
  {
    title: '跟进情况描述',
    dataIndex: 'remark',
    align: "center",
    width: '58%'
  },
  {
    title: '时间',
    dataIndex: 'createTime',
    align: "center",
    width: '29%',
    defaultSortOrder: 'descend',
    render:createTime=>{
      if(createTime && createTime.length>0){
        return moment(createTime).format('YYYY-MM-DD')
      }else{
        return ''
      }
    }
  },
  {
    title: '跟进人',
    dataIndex: 'createName',
    width: '8%',
    align: "center",
  }
]

class FollowList extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      loading:false,
      current:1,
      totalCount:0,
      dataSource:[],
      nextTime:''
    }
    this.FollowDetailModalRef = React.createRef();
  }

  componentDidMount(){
    this.loadData(this.props.resumeId,1);
  }

  loadData(resumeId,page){
    this.setState({
      loading:true
    })
    FollowApi.cms_follow_getByParam({
      page,
      pageSize:5,
      resumeId:resumeId
    })
    .then(({data})=>{
      let dataSource = [],totalCount=0;
      let last = {};
      if(data.code == '200'){
        totalCount = data.data.totalCount;
        dataSource = data.data.list.map((item,index)=>{
          return{
            key:item.id,
            index:index+1,
            ...item
          }
        })
        last = data.data.list[0] || {};
      }else{
        message.error(data.message)
      }
      this.setState({
        loading:false,
        dataSource,
        totalCount,
        current:page,
        nextTime:last.nextTime?moment(last.nextTime).format('YYYY-MM-DD'):''
      })
    })
    .catch(err=>{
      message.error(err.message);
    })
  }

  add(){
    this.FollowDetailModalRef.current.show();
  }

  onModalClose(){
    this.loadData(this.props.resumeId,1);
  }

  handleTableChange = (pagination, filters, sorter)=>{
    this.loadData(this.props.resumeId,pagination.current)
  }

  render(){
    return(
      <div className={style.page}>
        <div className={style.header}>
          <h2>追踪记录 <span>下次追踪时间：{this.state.nextTime}</span> </h2>
          {/* 按钮区域 */}
          <div className={style.btn}>
            <Button onClick={()=>this.add()}>增加记录</Button>
          </div>
        </div>
        <Table
          className={style.table}
          bordered={true}
          pagination={{
            pageSize: 5,
            current: this.state.current,
            total: this.state.totalCount,
            size:"small"
          }}
          onChange={this.handleTableChange}
          loading={this.state.loading}
          columns={columns}
          dataSource={this.state.dataSource} />
        {/* 详情编辑弹出层 */}
        <FollowDetailModal resumeId={this.props.resumeId} ref={this.FollowDetailModalRef}  onModalClose={()=>this.onModalClose()}/>
      </div>
    )
  }
}

export default FollowList;
