import React from 'react';

import style from './ContractDetail.less';

import {
  Form, Input, DatePicker, Button, message
} from 'antd';

import moment from 'moment';

import ContractApi from '../../api/ContractApi';

class ContractDetail extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      form:{
        resumeId:props.resumeId,
        id:props.id,
        dealTime:'',
        dealPrice:'',
        startTime:'',
        endTime:'',
        remark:''
      }
    }
  }

  componentDidMount(){
    if(this.state.form.id){
      ContractApi.cms_contract_getById(this.state.form.id)
      .then(({data})=>{
        if(data.code == '200'){
          this.setState({
            form:data.data
          })
        }else{
          message.error(data.message || '合同详情查询失败')
        }
      })
    }
  }

  save(){
    let {validateFields} = this.props.form;
    validateFields((err, values)=>{
      if(!err){
        values.id = this.state.form.id;
        values.resumeId = this.state.form.resumeId;
        values.dealTime = values.dealTime.startOf('day').format('YYYY-MM-DD HH:mm:SS');
        values.startTime = values.startTime.startOf('day').format('YYYY-MM-DD HH:mm:SS');
        values.endTime = values.endTime.startOf('day').format('YYYY-MM-DD HH:mm:SS');
        ContractApi.saveOrUpdate(values)
        .then(({data})=>{
          if(data.code == '200'){
            message.success('合同信息保存成功');
            this.props.onOk(data.data.id);
          }else{
            message.error(data.message || '合同信息保存失败');
          }
        })
      }
    })
  }

  close(){
    this.props.onOk(null);
  }

  formatDate(strDate){
    if(strDate && strDate.length>0){
      return moment(strDate).startOf('day')
    }else{
      return null
    }
  }

  render(){
    let {getFieldDecorator} = this.props.form;
    let {form} = this.state;
    return(
      <div className={style.page}>
        <Form className={style.form} layout="inline">
          <Form.Item label='成交时间'>
          {
            getFieldDecorator('dealTime',{
              initialValue:this.formatDate(form.dealTime),
              rules:[
                {
                  required:true,
                  message:'请选择成交时间'
                }
              ]
            })(
              <DatePicker format={'YYYY-MM-DD'}/>
            )
          }
          </Form.Item>
          <Form.Item label='成交价格'>
          {
            getFieldDecorator('dealPrice',{
              initialValue:form.dealPrice
            })(
              <Input />
            )
          }
          </Form.Item>
          <Form.Item label='合同开始时间'>
          {
            getFieldDecorator('startTime',{
              initialValue:this.formatDate(form.startTime),
              rules:[
                {
                  required:true,
                  message:'请选择合同开始时间'
                }
              ]
            })(
              <DatePicker format={'YYYY-MM-DD'}/>
            )
          }
          </Form.Item>
          <Form.Item label='合同到期时间'>
          {
            getFieldDecorator('endTime',{
              initialValue:this.formatDate(form.endTime),
              rules:[
                {
                  required:true,
                  message:'请选择合同到期时间'
                }
              ]
            })(
              <DatePicker format={'YYYY-MM-DD'}/>
            )
          }
          </Form.Item>
          <Form.Item label='备注'>
          {
            getFieldDecorator('remark',{
              initialValue:form.remark
            })(
              <Input />
            )
          }
          </Form.Item>
        </Form>
        <div className={style.btn}>
          <Button onClick={()=>this.save()}>保存</Button>
          <Button onClick={()=>this.close()}>关闭</Button>
        </div>
      </div>
    )
  }
}

export default Form.create({
  name:'ContractDetailForm'
})(ContractDetail);
