import Axios from "axios";
import moment from "moment";
import lodash from "lodash";

function cms_credentials_getByParam(params){
  return Axios.post('/cms/credentials/getByParam',{
    ...params
  })
}

function cms_credentials_delete(id){
  return Axios.get('cms/credentials/delete',{
    params:{
      id
    }
  })
}

function cms_credentials_getById(id){
  return Axios.get('cms/credentials/getById',{
    params:{
      id
    }
  })
}
// 编辑保存都用这个
function cms_credentials_save(diplomaDetail){
  return Axios.post('cms/credentials/save',{
    ...diplomaDetail
  })
}

export default {
  cms_credentials_getByParam,
  cms_credentials_delete,
  cms_credentials_getById,
  cms_credentials_save
}
