import React from 'react';
import moment from 'moment';

import {
  Table, message,Divider,Button,Modal
} from 'antd';

import style from './DiplomaList.less';

import CredentialApi from '../api/CredentialApi';

import CredentialDetailModal from './CredentialDetail';

const DatePickerFormat = "YYYY-MM-DD";
const confirm = Modal.confirm;
class CredentialList extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      loading:false,
      params:{
        page:0,
        pageSize:10,
      },
      dataSource:[]
    }
    this.columns = [
      {
        title: "序号",
        dataIndex: "index",
        width: "4%",
        align: "center"
      },
      {
        title: "证书种类",
        dataIndex: "credentialsType",
        width: "5%",
        align: "center"
      },
      {
        title: "证书专业",
        dataIndex: "credentialsMajor",
        width: "5%",
        align: "center"
      },
      {
        title: "证书级别",
        dataIndex: "credentialsLevel",
        width: "5%",
        align: "center"
      },
      {
        title: "发证地",
        dataIndex: "certificationAuthority",
        width: "5%",
        align: "center"
      },
      {
        title: "发证时间",
        dataIndex: "certificationTime",
        width: "10%",
        align: "center"
      },
      {
        title: "初始/转注",
        dataIndex: "init",
        width: "10%",
        align: "center"
      },
      {
        title: "现注册地",
        dataIndex: "registeredPlace",
        width: "10%",
        align: "center"
      },
      {
        title: "有效期",
        dataIndex: "validityTime",
        width: "10%",
        align: "center",
        defaultSortOrder: 'descend'
      },
      {
        title: "B证",
        dataIndex: "otherB",
        width: "5%",
        align: "center"
      },
      {
        title: "B证有效期",
        dataIndex: "validityTimeB",
        width: "10%",
        align: "center",
        defaultSortOrder: 'descend'
      },
      {
        title: "单证",
        dataIndex: "single",
        width: "5%",
        align: "center"
      },
      {
        title: "三证一表/网查",
        dataIndex: "net",
        width: "8%",
        align: "center"
      }
    ];

    this.columns.push({
      title: '操作',
      key: 'action',
      width: '10%',
      align: "center",
      render: (text, record) => (
        <span>
          <a href="javascript:;" onClick={()=>this.edit(record.id)} >编辑</a>
          <Divider type="vertical" />
          <a href="javascript:;" onClick={()=>this.deleted(record.id)}>删除</a>
        </span>
      ),
    })
    this.credentialDetailModalRef = React.createRef();
  }

  componentDidMount(){
    this.loadData(this.props.resumeId);
  }

  loadData(resumeId){
    this.setState({
      loading:true
    })
    CredentialApi.cms_credentials_getByParam({
      ...this.state.params,
      resumeId:resumeId
    })
    .then(({data})=>{
      let dataSource = [];
      if(data.code == '200'){
        dataSource = data.data.list.map((item,index)=>{
          if(item.certificationTime){
            item.certificationTime = moment(item.certificationTime).format(DatePickerFormat);
          }else{
            item.certificationTime = null;
          }
          if(item.validityTime){
            item.validityTime = moment(item.validityTime).format(DatePickerFormat);
          }else{
            item.validityTime = null;
          }
          if(item.validityTimeB){
            item.validityTimeB = moment(item.validityTimeB).format(DatePickerFormat);
          }else{
            item.validityTimeB = null;
          }
          return{
            key:item.id,
            index:index+1,
            ...item
          }
        })
      }else{
        message.error(data.message)
      }
      this.setState({
        loading:false,
        dataSource
      })
    })
    .catch(err=>{
      message.error(err.message);
    })
  }

  edit(id){
    this.credentialDetailModalRef.current.show(id,'证书编辑');
  }
  add(){
    this.credentialDetailModalRef.current.show(null,'新增证书');
  }

  deleted(id){
    confirm({
      title: '确定删除吗?',
      content: '删除后将无法恢复数据，请谨慎操作！',
      okText: '确定',
      okType: 'danger',
      cancelText: '取消',
      onOk:()=>{
        this.sureDelete(id);
      }
    });
  }

  sureDelete(id){
    message.loading('正在删除...')
    CredentialApi.cms_credentials_delete(id)
    .then(({data})=>{
      message.destroy();
      if(data.code == '200'){
        message.success('删除成功');
        this.loadData(this.props.resumeId);
      }else{
        message.error(data.message || '删除失败')
      }
    })
    .catch(err=>{
      message.destroy();
      message.error(err.message)
    })
  }

  onModalClose(){
    this.loadData(this.props.resumeId);
  }

  render(){
    return(
      <div className={style.page}>
        <div className={style.header}>
          <h2>证书信息</h2>
          {/* 按钮区域 */}
          <div className={style.btn}>
            <Button onClick={()=>this.add()}>新增证书</Button>
          </div>
        </div>
        <Table
          className={style.table}
          bordered={true}
          pagination={false}
          loading={this.state.loading}
          columns={this.columns}
          dataSource={this.state.dataSource} />
        {/* 详情编辑弹出层 */}
        <CredentialDetailModal resumeId={this.props.resumeId} ref={this.credentialDetailModalRef}  onModalClose={()=>this.onModalClose()}/>
      </div>
    )
  }
}

export default CredentialList;
