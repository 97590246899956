import React from 'react';

import {
  Modal
} from 'antd';

import style from './Modal.less';

import BaseInfo from './BaseInfo';

import ContractList from '../Contract/List';

import DiplomaList from '../DiplomaList';

import CredentialList from '../CredentialList';
import FollowList from '../FollowList';

const ModalBaseOptions = {
  width:'100%',
  title:'人才信息',
  wrapClassName:style.page,
  footer:null,
  destroyOnClose:true
}

class ResumesModal extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      visible:false,
      id:null,
      level:null
    }
  }

  show(type,id,level){
    this.setState({
      visible:true,
      type,
      id,
      level
    })
  }
  onCancel = ()=>{
    this.setState({
      visible:false
    })
  }
  onSave(id){
    this.setState({
      id
    })
  }
  updateLevel(level){
    this.setState({
      level
    })
  }
  render(){
    let {visible,type,id,level} = this.state;
    return(
      <Modal {...ModalBaseOptions}
        wrapClassName={style.modalwrap}
        onCancel={this.onCancel}
        visible={visible}>
        <div className={style.block}>
          {/* 简历基本信息 */}
          <BaseInfo defaultType={type} defaultId={id} onSave={(id)=>this.onSave(id)} updateLevel={(level)=>{this.updateLevel(level)}}/>
        </div>
        {/* 合同信息 */}
        <div className={style.block}>
          {
            (id&&level=='A')&&<ContractList resumeId={id} />
          }
        </div>

        {/* 毕业证信息 */}
        <div className={style.block}>
          {
            id && <DiplomaList resumeId={id}/>
          }
        </div>
        {/* 证书信息 */}
        <div className={style.block}>
          {
            id && <CredentialList resumeId={id}/>
          }
        </div>
        {/* 跟踪记录 */}
        <div className={style.block}>
          {
            id && <FollowList resumeId={id}/>
          }
        </div>
      </Modal>
    )
  }
}

export default ResumesModal;