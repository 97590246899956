/**
 * 证书详情
 */
import React from 'react';
import moment from 'moment';
import lodash from 'lodash';

import {
  Modal, message,
  DatePicker,
  Select,
  Input,
  Button,
  Form
} from 'antd';
import CredentialApi from '../api/CredentialApi';
const FormItem = Form.Item;
const DatePickerFormat = "YYYY-MM-DD";
const DateSubmitFormat = "YYYY-MM-DD HH:mm:SS";
const FormOption = {
  layout: "vertical"
};
const FormItemOption = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 }
};

export class CredentialDetail extends React.Component {

  constructor(props){
    super(props);
    FormOption.onSubmit = this.save.bind(this);
    this.state = {
      loading:false,
      credentialDetail:{
        id:props.credentialId,
        // 发证时间
        certificationTime:null,
        // 有效期
        validityTime:null,
        // B证有效期
        validityTimeB:null
      }
    }
  }

  componentDidMount(){
    // 如果是编辑，查询证书详情
    if(this.props.credentialId){
      this.loadData(this.props.credentialId);
    }
  }

  loadData(id){
    message.loading('数据加载...')
    CredentialApi.cms_credentials_getById(id)
    .then(({data})=>{
      message.destroy();
      let credentialDetail = {};
      if(data.code == '200'){
        credentialDetail = data.data;
      }else{
        message.error(data.message || '证书详情查询失败');
      }
      // 处理下日期数据
      if(credentialDetail.certificationTime){
        credentialDetail.certificationTime = moment(credentialDetail.certificationTime);
      }else{
        credentialDetail.certificationTime = null;
      }
      if(credentialDetail.validityTime){
        credentialDetail.validityTime = moment(credentialDetail.validityTime);
      }else{
        credentialDetail.validityTime = null;
      }
      if(credentialDetail.validityTimeB){
        credentialDetail.validityTimeB = moment(credentialDetail.validityTimeB);
      }else{
        credentialDetail.validityTimeB = null;
      }
      this.setState({
        credentialDetail
      })
    })
    .catch(err=>{
      message.destroy();
      message.error(err.message || '证书详情查询失败')
    })
  }

  save(e){
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({
          loading:true
        })
        lodash.forEach(values,(val,key)=>{
          if(val === undefined){
            values[key] = '';
          }
        })
        CredentialApi.cms_credentials_save({
          ...values,
          id: this.state.credentialDetail.id,
          certificationTime: values.certificationTime ? moment(values.certificationTime).format(DateSubmitFormat):"",
          validityTime: values.validityTime? moment(values.validityTime).format(DateSubmitFormat): "",
          validityTimeB: values.validityTimeB? moment(values.validityTimeB).format(DateSubmitFormat): "",
          resumeId: this.props.resumeId
        })
          .then(({ data }) => {
            if (data.code == "200") {
              this.props.onSave(data.data);
            } else {
              message.error(data.message);
            }
            this.setState({
              loading: false
            });
          })
          .catch(error => {
            message.error(error.message);
            this.setState({
              submit: false
            });
          });
      }
    });
  }

  render(){
    let {credentialDetail,loading} = this.state;
    const { getFieldDecorator } = this.props.form;
    return(
      <div>
        <Form {...FormOption}>
          <div className="contract-form-row">
            <FormItem {...FormItemOption} label="证书种类">
              {getFieldDecorator("credentialsType", {
                initialValue: credentialDetail.credentialsType
              })(
                <Select placeholder="请选择证书种类" allowClear={true}>
                  <Select.Option value="工程师证">工程师证</Select.Option>
                  <Select.Option value="建造师证">建造师证</Select.Option>
                  <Select.Option value="其他证书">其他证书</Select.Option>
                </Select>
              )}
            </FormItem>
            <FormItem {...FormItemOption} label="证书专业">
              {getFieldDecorator("credentialsMajor", {
                initialValue: credentialDetail.credentialsMajor
              })(<Input placeholder="请输入证书专业" />)}
            </FormItem>
          </div>

          <div className="contract-form-row">
            <FormItem {...FormItemOption} label="证书级别">
              {getFieldDecorator("credentialsLevel", {
                initialValue: credentialDetail.credentialsLevel
              })(
                <Select placeholder="请选择证书级别" allowClear={true}>
                  <Select.Option value="中级">中级</Select.Option>
                  <Select.Option value="高级">高级</Select.Option>
                  <Select.Option value="正高级">正高级</Select.Option>
                  <Select.Option value="一级">一级</Select.Option>
                  <Select.Option value="二级">二级</Select.Option>
                  <Select.Option value="三级">三级</Select.Option>
                </Select>
              )}
            </FormItem>
            <FormItem {...FormItemOption} label="发证地">
              {getFieldDecorator("certificationAuthority", {
                initialValue: credentialDetail.certificationAuthority
              })(<Input placeholder="请输入发证地" />)}
            </FormItem>
          </div>

          <div className="contract-form-row">
            <FormItem {...FormItemOption} label="发证时间">
              {getFieldDecorator("certificationTime", {
                initialValue: credentialDetail.certificationTime
              })(
                <DatePicker
                  placeholder="请输入发证时间"
                  format={DatePickerFormat}
                />
              )}
            </FormItem>
            <FormItem {...FormItemOption} label="初始/转注">
              {getFieldDecorator("init", {
                initialValue: credentialDetail.init
              })(<Input placeholder="请输入初始/转注" />)}
            </FormItem>
          </div>

          <div className="contract-form-row">
            <FormItem {...FormItemOption} label="现注册地">
              {getFieldDecorator("registeredPlace", {
                initialValue: credentialDetail.registeredPlace
              })(<Input placeholder="请输入现注册地" />)}
            </FormItem>
            <FormItem {...FormItemOption} label="有效期">
              {getFieldDecorator("validityTime", {
                initialValue: credentialDetail.validityTime
              })(
                <DatePicker
                  placeholder="请输入有效期"
                  format={DatePickerFormat}
                />
              )}
            </FormItem>
          </div>

          <div className="contract-form-row">
            <FormItem {...FormItemOption} label="B证">
              {getFieldDecorator("otherB", {
                initialValue: credentialDetail.otherB
              })(
                <Select allowClear={true}>
                  <Select.Option value="有">有</Select.Option>
                  <Select.Option value="无">无</Select.Option>
                </Select>
              )}
            </FormItem>
            <FormItem {...FormItemOption} label="B证有效期">
              {getFieldDecorator("validityTimeB", {
                initialValue: credentialDetail.validityTimeB
              })(
                <DatePicker
                  placeholder="请输入B证有效期"
                  format={DatePickerFormat}
                />
              )}
            </FormItem>
          </div>

          <div className="contract-form-row">
            <FormItem {...FormItemOption} label="单证">
              {getFieldDecorator("single", {
                initialValue:credentialDetail.single
              })(
                <Select allowClear={true}>
                  <Select.Option value="是">是</Select.Option>
                  <Select.Option value="否">否</Select.Option>
                </Select>
              )}
            </FormItem>
            <FormItem {...FormItemOption} label="三证一表/网查 ">
              {getFieldDecorator("net", {
                initialValue: credentialDetail.net
              })(<Input placeholder="请输入三证一表/网查 " />)}
            </FormItem>
          </div>
          <div className="contract-form-row contract-form-btn">
            <FormItem>
              <Button
                type="primary"
                htmlType="submit"
                block={true}
                loading={loading}
              >
                保存
              </Button>
            </FormItem>
          </div>
        </Form>
      </div>
    )
  }
}

const CredentialFormDetail = Form.create({ name: "edit-or-add-form" })(
  CredentialDetail
);


class CredentialDetailModal extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      title:'',
      visible:false,
      credentialId:null,
      credentialDetail:{}
    }
  }
  show(credentialId,title){
    this.setState({
      visible:true,
      credentialId,
      title
    })
  }
  onOk = ()=>{
    this.setState({
      visible:true
    })
  }
  onCancel(){
    this.setState({
      visible:false
    })
  }
  onSave(){
    this.onCancel();
    this.props.onModalClose();
  }
  render(){
    let {visible,credentialId,title} = this.state;
    return(
      <div>
        <Modal
          title={title}
          visible={visible}
          onOk={this.onOk}
          width={'800px'}
          footer={null}
          destroyOnClose={true}
          onCancel={()=>this.onCancel()}>
        {
          <CredentialFormDetail resumeId={this.props.resumeId}  credentialId={credentialId} onSave={()=>this.onSave()}/>
        }
        </Modal>
      </div>
    )
  }
}

export default CredentialDetailModal;
