import React from 'react';
import moment from 'moment';

import {
  Table, message,Divider,Button,Modal
} from 'antd';

import style from './DiplomaList.less';

import DiplomaApi from '../api/DiplomaApi';

import DiplomaDetailModal from './DiplomaDetail';

const confirm = Modal.confirm;

class DiplomaList extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      loading:false,
      params:{
        page:0,
        pageSize:100,
      },
      dataSource:[]
    }
    this.columns = [
      {
        title: '序号',
        dataIndex: 'index',
        align: "center",
        width: '5%'
      },
      {
        title: '毕业时间',
        dataIndex: 'diplomaTime',
        align: "center",
        width: '29%',
        render:diplomaTime=>{
          if(diplomaTime && diplomaTime.length>0){
            return moment(diplomaTime).format('YYYY-MM-DD')
          }else{
            return ''
          }
        }
      },
      {
        title: '毕业专业',
        dataIndex: 'diplomaMajor',
        align: "center",
        width: '29%'
      },
      {
        title: '学历',
        dataIndex: 'diplomaLevel',
        width: '29%',
        align: "center",
      }
    ]
    this.columns.push({
      title: '操作',
      key: 'action',
      width: '10%',
      align: "center",
      render: (text, record) => (
        <span>
          <a href="javascript:;" onClick={()=>this.edit(record.id)} >编辑</a>
          <Divider type="vertical" />
          <a href="javascript:;" onClick={()=>this.deleted(record.id)}>删除</a>
        </span>
      ),
    })
    this.diplomaDetailModalRef = React.createRef();
  }

  componentDidMount(){
    this.loadData(this.props.resumeId);
  }

  loadData(resumeId){
    this.setState({
      loading:true
    })
    DiplomaApi.cms_diploma_getByParam({
      ...this.state.params,
      resumeId:resumeId
    })
    .then(({data})=>{
      let dataSource = [];
      if(data.code == '200'){
        dataSource = data.data.list.map((item,index)=>{
          return{
            key:item.id,
            index:index+1,
            ...item
          }
        })
      }else{
        message.error(data.message)
      }
      this.setState({
        loading:false,
        dataSource
      })
    })
    .catch(err=>{
      message.error(err.message);
    })
  }

  edit(id){
    this.diplomaDetailModalRef.current.show(id,'毕业证编辑');
  }
  add(){
    this.diplomaDetailModalRef.current.show(null,'新增毕业证');
  }

  deleted(id){
    confirm({
      title: '确定删除吗?',
      content: '删除后将无法恢复数据，请谨慎操作！',
      okText: '确定',
      okType: 'danger',
      cancelText: '取消',
      onOk:()=>{
        this.sureDelete(id);
      }
    });
  }

  sureDelete(id){
    message.loading('正在删除...')
    DiplomaApi.cms_diploma_delete(id)
    .then(({data})=>{
      message.destroy();
      if(data.code == '200'){
        message.success('删除成功');
        this.loadData(this.props.resumeId);
      }else{
        message.error(data.message || '删除失败')
      }
    })
    .catch(err=>{
      message.destroy();
      message.error(err.message)
    })
  }

  onModalClose(){
    this.loadData(this.props.resumeId);
  }

  render(){
    return(
      <div className={style.page}>
        <div className={style.header}>
          <h2>毕业证信息</h2>
          {/* 按钮区域 */}
          <div className={style.btn}>
            <Button onClick={()=>this.add()}>新增毕业证</Button>
          </div>
        </div>
        <Table
          className={style.table}
          bordered={true}
          pagination={false}
          loading={this.state.loading}
          columns={this.columns}
          dataSource={this.state.dataSource} />
        {/* 详情编辑弹出层 */}
        <DiplomaDetailModal resumeId={this.props.resumeId} ref={this.diplomaDetailModalRef}  onModalClose={()=>this.onModalClose()}/>
      </div>
    )
  }
}

export default DiplomaList;
