/**
 * 毕业证详情
 */
import React from 'react';
import moment from 'moment';

import {
  Modal, message,
  DatePicker,
  Select,
  Input,
  Button
} from 'antd';

import style from './DiplomaDetail.less';
import DiplomaApi from '../api/DiplomaApi'

export class DiplomaDetail extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      loading:false,
      diplomaDetail:{}
    }
  }

  componentDidMount(){
    if(this.props.diplomaId){
      this.loadData(this.props.diplomaId);
    }
  }

  loadData(id){
    message.loading('数据加载...')
    DiplomaApi.cms_diploma_getById(id)
    .then(({data})=>{
      message.destroy();
      let diplomaDetail = {};
      if(data.code == '200'){
        diplomaDetail = data.data;
      }else{
        message.error(data.message || '毕业证详情查询失败');
      }
      // 处理下日期数据
      if(diplomaDetail.diplomaTime){
        diplomaDetail.diplomaTime = moment(diplomaDetail.diplomaTime);
      }else{
        diplomaDetail.diplomaTime = null;
      }
      this.setState({
        diplomaDetail
      })
    })
    .catch(err=>{
      message.destroy();
      message.error(err.message || '毕业证详情查询失败')
    })
  }

  save(){
    this.setState({
      loading:true
    })
    let diplomaDetail = {
      diplomaTime:this.state.diplomaDetail.diplomaTime,
      diplomaMajor:this.state.diplomaDetail.diplomaMajor,
      diplomaLevel:this.state.diplomaDetail.diplomaLevel,
      id:this.state.diplomaDetail.id,
      resumeId:this.props.resumeId
    };
    // 处理日期
    if(diplomaDetail.diplomaTime){
      diplomaDetail.diplomaTime = diplomaDetail.diplomaTime.startOf('day').format('YYYY-MM-DD HH:mm:SS')
    }else{
      diplomaDetail.diplomaTime = ""
    }

    DiplomaApi.cms_diploma_save(diplomaDetail)
    .then(({data})=>{
      if(data.code == '200'){
        message.success('保存成功');
        if(this.props.onSave){
          this.props.onSave();
        }
      }else{
        message.error(data.message || '保存失败');
        this.setState({
          loading:false
        })
      }
      this.setState({
        loading:false
      })
    })
    .catch(err=>{
      message.error(err.message || '保存失败');
      this.setState({
        loading:false
      })
    })
  }

  onDateChange(date,ateString){
    this.setState({
      diplomaDetail:{
        ...this.state.diplomaDetail,
        diplomaTime:date
      }
    })
  }
  onInputChange(e){
    this.setState({
      diplomaDetail:{
        ...this.state.diplomaDetail,
        diplomaMajor:e.target.value
      }
    })
  }
  onSelectChange(val){
    if(val === undefined){
      val = '';
    }
    this.setState({
      diplomaDetail:{
        ...this.state.diplomaDetail,
        diplomaLevel:val
      }
    })
  }
  render(){
    let {diplomaDetail,loading} = this.state;
    return(
      <div className={style.page}>
        <div className={style['form-wrap']}>
          <div className={style['label-wrap']}>
            <span className={style.label}>毕业时间</span>
            <DatePicker
              format={'YYYY-MM-DD'}
              placeholder='请选择毕业时间'
              value={diplomaDetail.diplomaTime}
              onChange={(date,dateString)=>this.onDateChange(date,dateString)}
            />
          </div>
          <div className={style['label-wrap']}>
            <span className={style.label}>毕业专业</span>
            <Input value={diplomaDetail.diplomaMajor} placeholder={'请输入毕业专业'} onChange={(e)=>this.onInputChange(e)}/>
          </div>
          <div className={style['label-wrap']}>
            <span className={style.label}>学历</span>
            <Select value={diplomaDetail.diplomaLevel} placeholder={'请选择学历'} allowClear={true}  onChange={(val)=>this.onSelectChange(val)}>
              <Select.Option value={'中专'}>中专</Select.Option>
              <Select.Option value={'大专'}>大专</Select.Option>
              <Select.Option value={'本科'}>本科</Select.Option>
              <Select.Option value={'硕士'}>硕士</Select.Option>
              <Select.Option value={'博士'}>博士</Select.Option>
            </Select>
          </div>
        </div>
        {/* 按钮区域 */}
        <div className={style.action}>
          <Button onClick={()=>this.save()} loading={loading}>保存</Button>
        </div>
      </div>
    )
  }
}


class DiplomaDetailModal extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      visible:false,
      diplomaId:null,
      diplomaDetail:{}
    }
  }
  show(diplomaId,title){
    this.setState({
      visible:true,
      diplomaId,
      title
    })
  }
  onOk = ()=>{
    this.setState({
      visible:true
    })
  }
  onCancel(){
    this.setState({
      visible:false
    })
  }
  onSave(){
    this.onCancel();
    this.props.onModalClose();
  }
  render(){
    let {visible,diplomaId,title} = this.state;
    return(
      <div className={style.modal}>
        <Modal
          title={title}
          visible={visible}
          onOk={this.onOk}
          width={'800px'}
          footer={null}
          destroyOnClose={true}
          onCancel={()=>this.onCancel()}>
        {
          <DiplomaDetail resumeId={this.props.resumeId}  diplomaId={diplomaId} onSave={()=>this.onSave()}/>
        }
        </Modal>
      </div>
    )
  }
}

export default DiplomaDetailModal;
