/**
 * 搜索页面 数据列表的表头
 */

const baseColums = [
  {
    title:'序号',
    dataIndex:'index',
    width: '3%'
  },
  {
    title: '姓名',
    dataIndex: 'realName',
    width: '5%'
  }, {
    title: '证书种类',
    dataIndex: 'credentialsType',
    width: '5%'
  }, {
    title: '证书专业',
    dataIndex: 'credentialsMajor',
    width: '5%'
  }, {
    title: '证书等级',
    dataIndex: 'credentialsLevel',
    width: '5%'
  }, {
    title: '发证地',
    dataIndex: 'certificationAuthority',
    width: '3%'
  }, {
    title: '发证时间',
    dataIndex: 'certificationTime',
    width: '8%',
    sorter: true
  }, {
    title: '现注册地',
    dataIndex: 'registeredPlace',
    width: '5%'
  }, {
    title: '证书有效期',
    dataIndex: 'validityTime',
    width: '8%',
    sorter: true
  }, {
    title: 'B证',
    dataIndex: 'otherB',
    width: '3%'
  }, {
    title: 'B证有效期',
    dataIndex: 'validityTimeB',
    width: '8%',
    sorter: true
  }, {
    title: '是否为单证',
    dataIndex: 'single',
    width: '6%'
  }
]

const columns = [
  ...baseColums, {
    title: '三证一表/网查结果',
    dataIndex: 'net',
  }, {
    title: '下次联系日期',
    dataIndex: 'nextTime',
    width: '8%',
    sorter: true
  },{
    title: '上次联系日期',
    dataIndex: 'lastTime',
    width: '8%',
    sorter: true
  },  {
    title: '上次联系情况',
    dataIndex: 'des',
    width: '10%'
  }
]

const ownerColums = [
  ...baseColums, {
    title: '合同开始日期',
    dataIndex: 'contracts_startTime',
    width: "7%",
    defaultSortOrder: 'descend',
    sorter: (a, b) => {
      if(a.contracts_startTime){
        return 1;
      }
      return a.contracts_startTime > b.contracts_startTime;
    },
  }, {
    title: '合同结束日期',
    dataIndex: 'contracts_endTime',
    width: "7%",
    defaultSortOrder: 'descend',
    sorter: (a, b) => {
      if(a.contracts_endTime){
        return 1;
      }
      return a.contracts_endTime > b.contracts_endTime;
    },
  }
]
const errorColums = [
  {
    title: '姓名',
    dataIndex: 'realName',
  },{
    title: '年龄',
    dataIndex: 'age',
  },{
    title: '联系地址',
    dataIndex: 'address',
  }, {
    title: '邮箱',
    dataIndex: 'email',
  }, {
    title: 'QQ',
    dataIndex: 'qq',
  }, {
    title: '联系电话',
    dataIndex: 'telephone',
  }, {
    title: '其他联系方式',
    dataIndex: 'phone',
  }, {
    title: '联系人',
    dataIndex: 'follower',
  }, {
    title: '身份证',
    dataIndex: 'idcard',
  }, {
    title: '简历级别',
    dataIndex: 'level',
  }, {
    title: '上传失败原因',
    dataIndex: 'remark',
  }, {
    title: '上传时间',
    dataIndex: 'createTime',
    defaultSortOrder: 'descend'
  }
]
ownerColums.map(col => {
  return {
    ...col,
    align: 'center'
  }
})
export const ownerColumsRes = ownerColums.map(col => {
  return {
    ...col,
    align: 'center'
  }
});
export const errorColumsRes = errorColums.map(col => {
  return {
    ...col,
    align: 'center'
  }
});
export default columns.map((col) => {
  return {
    ...col,
    align: 'center'
  }
})
