import Axios from "axios";

/**
 * 新增或者更新合同
 */
function saveOrUpdate(contract) {
  return Axios.post("/cms/contract/save", contract);
}

/**
 * 删除合同
 */
function deleteById(id) {
  return Axios.get("/cms/contract/delete", {
    params: {
      id
    }
  });
}

/**
 * 查询合同列表
 */
function cms_contract_getByParam(params){
  return Axios.post('/cms/contract/getByParam',params)
}

// 查询合同详情
function cms_contract_getById(id){
  return Axios.get('/cms/contract/getById',{
    params:{
      id
    }
  })
}


export default {
  cms_contract_getByParam,
  saveOrUpdate,
  deleteById,
  cms_contract_getById
};
