import React from 'react';
import { Modal } from 'antd';

import style from './Modal.less';

const ModalBaseOptions = {
  width:'90%',
  title:'合同信息',
  wrapClassName:style.modal,
  footer:null,
  destroyOnClose:true
}

import ContractDetail from './ContractDetail';

class ContractDetailModal extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      visible:false,
      resumeId:null,
      id:null
    }
  }
  show(id,resumeId){
    this.setState({
      visible:true,
      id,
      resumeId
    })
  }
  onCancel = ()=>{
    this.setState({
      visible:false
    })
  }
  onOk(id){
    if(id){
      this.props.onOk(id);
    }
    this.setState({
      visible:false
    })
  }
  render(){
    let {visible,resumeId,id} = this.state;
    return(
      <div className={style.page}>
        <Modal {...ModalBaseOptions}
          onCancel={this.onCancel}
          visible={visible}>
          {
            visible&&<ContractDetail id={id} resumeId={resumeId} onOk={(id)=>this.onOk(id)}/>
          }
        </Modal>
      </div>
    )
  }
}

export default ContractDetailModal;