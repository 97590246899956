import React from 'react';
import { Table, Divider ,Icon, Modal, Form, Row, Col, Input, Button, Select, message } from 'antd';
import { cms_bermem_save,cms_member_get,cms_bermem_delete } from "../services/QueryApi";

const FormItem = Form.Item;
const confirm = Modal.confirm;

class StaffPage extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      columns:this.createColumns(),
      dataSource:[],
      visible:false,
      loading:false,
      current:1,
      totalCount:0,
      submit:false,
      memberId:null,
      editMemberObj:{},
    }
  }

  createColumns(){
    let columns = [{
      title: '序号',
      dataIndex: 'index',
      key: 'index',
    }, {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
    }, {
      title: '用户名',
      key: 'userName',
      dataIndex: 'userName',
    }, {
      title: '真实姓名',
      key: 'realName',
      dataIndex:'realName',
    }, {
      title: '员工级别',
      dataIndex: 'level',
      key: 'level',
    }, {
      title: '操作',
      key: 'Option',
      dataIndex:'Option',
      render: (text, record) => (
        <span>
          <a href="javascript:;" onClick={()=>{this.editMember(record)}}>编辑</a>
          <Divider type="vertical" />
          <a href="javascript:;" onClick={()=>{this.deleteMember(record)}}>删除</a>
        </span>
      ),
    }];
    return columns;
  }

  deleteMember(record){
    confirm({
        title: '确定要删除改用户吗？',
        content: '点击确认删除此用户，请谨慎操作！',
        okText: '确认',
        okType: 'danger',
        cancelText: '取消',
        onOk:()=>{
          cms_bermem_delete(record.id).then(res => {
            let { data } = res;
            if (data.code == "200") {
              message.success("删除成功");
              that.pageChange(that.state.current);
            } else {
              message.error(data.message);
            }
          })
          .catch(err => {
            message.error(err.message);
          });
        }
      });
  }

  editMember(record){
    this.setState({editMemberObj:record});
    const { setFieldsValue } = this.props.form;
    setFieldsValue({
      'userName': record.userName,
      'level': record.level,
      'realName': record.realName,
      'pwd': '123456',
    });
    this.showAddUserModal(record.id);
  }

  getMemberListData(page){
    this.setState({
      loading: true
    });
    let params = {
      page,
      "pageSize": 15,
    }
    cms_member_get().then(res => {
      let { data } = res;
      if (data.code == "200") {
        this.modifyMemberData(data,page);
      } else {
        message.error(data.message);
      }
      this.setState({
        loading: false
      });
    })
    .catch(err => {
      message.error(err.message);
      this.setState({
        loading: false
      });
    });
  }

  modifyMemberData(data, current){
    let { list, totalCount } = data.data;
    let dataSource = [];
    list.map((member, index1) => {
      let first = {};
      first.key = member.id;
      first.index = index1+1;
      first.id = member.id;
      first.userName = member.userName||'';
      first.realName = member.realName||'';
      first.level = member.level==0?"管理员":"普通员工";
      dataSource.push(first);
    });
    this.setState({
      dataSource,
      totalCount,
      current: current
    });
  }

  pageChange(page){
    this.getMemberListData(page);
  }

  showAddUserModal(id){
    let {memberId,editMemberObj} = this.state;
    if(id){
      memberId = id;
    }else{
      memberId = null;
      editMemberObj = {};
    }
    this.setState({
      visible:true,
      memberId,
      editMemberObj
    })
  }

  modalClose = () => {
    this.setState({
      visible: false
    });
  };

  handleSave = e=>{e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        let {level} = values;
        if(level&&level=="普通员工"){
          values.level = 1;
        }else if(level&&level=="管理员"){
          values.level = 0;
        }
        values = {
          ...values,
          id: this.state.memberId
        };
        cms_bermem_save(values)
          .then(res => {
            let { data } = res;
            if (data.code == "200") {
              this.modalClose();
              message.success("新增成功");
              this.pageChange(this.state.current);
            } else {
              message.error(data.message);
            }
            this.setState({
              loading: false
            });
          })
          .catch(err => {
            message.error(err.message);
            this.setState({
              loading: false
            });
          });
      }
    });
  }

  componentDidMount(){
    this.getMemberListData(1);
  }

  render(){
    const { getFieldDecorator } = this.props.form;
    const { editMemberObj,memberId } = this.state;
    return(
      <div className="panel-user-info">
        <h3>用户列表<a style={{'float':'right','marginRight':'20px'}} href="javascript:;" onClick={()=>{this.showAddUserModal()}}>新增用户</a></h3>
        <Table
          columns={this.state.columns}
          dataSource={this.state.dataSource}
          loading={this.state.loading}
          pagination={{
            pageSize: 15,
            current: this.state.current,
            total: this.state.totalCount,
            onChange: (page)=>this.pageChange(page)
          }}
        />
        <Modal
          onCancel={() => {
            this.modalClose();
          }}
          className="resumes-modal"
          title={memberId?"编辑用户信息":"新增用户"}
          width={"40%"}
          visible={this.state.visible}
          footer={null}
        >
          <Form onSubmit={this.handleSave}>
            <Row gutter={24}>
              <Col span={12}>
                <FormItem label="用户名">
                  {getFieldDecorator("userName", {
                    rules: [
                      {
                        required: true,
                        message: "请输入用户名",
                      }
                    ]
                  })(<Input placeholder="" />)}
                </FormItem>
                <FormItem label="员工级别">
                  {getFieldDecorator("level", {
                    rules: [
                      {
                        required: true,
                        message: "请选择员工级别",
                      }
                    ]
                  })(
                    <Select>
                      <Select.Option value="0">管理员</Select.Option>
                      <Select.Option value="1">普通员工</Select.Option>
                    </Select>
                )}
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="真实姓名">
                  {getFieldDecorator("realName", {
                    rules: [
                      {
                        required: true,
                        message: "请输入真实姓名",
                      }
                    ]
                  })(<Input placeholder="请输入真实姓名" />)}
                </FormItem>
                <FormItem label="设置密码">
                  {getFieldDecorator("pwd", {
                    rules: [
                      {
                        required: true,
                        message: "请设置密码",
                      }
                    ]
                  })(<Input type="password" placeholder="请设置员工登录密码" disabled={memberId?true:false} />)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <FormItem>
                <Button
                  type="primary"
                  htmlType="submit"
                  block={true}
                  loading={this.state.submit}
                >
                  保存
                </Button>
              </FormItem>
            </Row>
          </Form>
        </Modal>
      </div>
    )
  }
}

StaffPage = Form.create()(StaffPage);

export default StaffPage;
